<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/cursos'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/cursos')"
              >Cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/curso/' +
                    $route.params.id_secretaria_curso
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/curso/' +
                  $route.params.id_secretaria_curso)"
              >Curso - {{ fastSecretariaCurso.nome_curso }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                v-for="programa in fastSecretariaCurso.programas"
                :key="programa.id_secretaria_curso_programa"
                class="aluno_font_color"
                href="#"
              >
                <span
                  v-if="
                    programa.id_secretaria_curso_programa ==
                      $route.params.id_secretaria_curso_programa
                  "
                >{{ programa.sigla_programa }}</span>
              </a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Curso
                  <span>{{ fastSecretariaCurso.nome_curso }}</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <transition name="slide-fade">
                    <div
                      v-if="fastSecretariaCurso.id_secretaria_curso"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Detalhes do curso {{ fastSecretariaCurso.nome_curso }}
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="m-0">
                                  Descrição:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.detalhe_curso
                                  }}</span>
                                </p>
                                <p class="m-0">
                                  Publicado:
                                  <span
                                    v-if="fastSecretariaCurso.publicado"
                                    class="text-success"
                                  >Sim</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Não</span>
                                </p>
                                <p class="m-0">
                                  Carga horária:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.carga_horaria
                                  }}</span>
                                </p>                              
                                <p
                                  v-if="fastSecretariaCurso.creditos_total"
                                  class="m-0"
                                >                                
                                  Créditos:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.creditos_total
                                  }}</span>
                                </p>
                              </div>
                              <div
                                v-if="fastSecretariaCurso.logo_curso"
                                class="col-sm-12 col-md-12 col-lg-6"
                              >
                                <p class="m-0">
                                  Logo do curso:
                                </p>
                                <img
                                  :src="ajustaLinkApiAntiga(fastSecretariaCurso.logo_curso)"
                                  class="img-responsive"
                                  style="max-width: 100%"
                                >                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <transition name="slide-fade">
                    <div
                      v-if="fastSecretariaCurso.programas.length"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6
                          v-for="programa in fastSecretariaCurso.programas"
                          :key="programa.id_secretaria_curso_programa"
                          class="m-0 font-weight-bold text-primary"
                        >
                          <span
                            v-if="
                              programa.id_secretaria_curso_programa ==
                                $route.params.id_secretaria_curso_programa
                            "
                          >{{ programa.sigla_programa }}</span>
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div
                            v-for="programa in fastSecretariaCurso.programas"
                            :key="programa.id_secretaria_curso_programa"
                            class="col-12"
                          >
                            <div
                              v-if="
                                programa.id_secretaria_curso_programa ==
                                  $route.params.id_secretaria_curso_programa
                              "
                              class="row"
                            >
                              <div class="col-sm-12 col-md-12 col-lg-4">
                                <p
                                  v-if="programa.creditos"
                                  class="m-0 pb-2"
                                >
                                  Créditos:
                                  <input
                                    type="text"
                                    class="form-control"
                                    :value="programa.creditos"
                                    readonly
                                  >
                                </p>
                                <div
                                  v-if="programa.id_pai"
                                  class="m-0"
                                >
                                  Pré-requisito:
                                  <p
                                    v-for="programa2 in fastSecretariaCurso.programas"
                                    :key="programa2.id_secretaria_curso_programa"
                                    class="aluno_font_color"
                                    href="#"
                                  >
                                    <input
                                      v-if="programa.id_pai == programa2.id_secretaria_curso_programa"
                                      :value="programa2.sigla_programa"
                                      type="text"
                                      class="form-control"
                                      readonly
                                    >                                  
                                  </p>                               
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-2">
                  <transition name="slide-fade">
                    <div v-if="!fastAvaliacaoModelo.loading" class="card shadow mb-4">
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                          <span>Avaliações</span>
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                            <label>Ativar</label>
                            <div class="fd-app-curso-aulas mt-0">
                              <div class="fd-app-curso-aulas-list mt-0">
                                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <div class="col-12 text-center">
                                      <label class="switch">
                                        <input
                                          v-model="fastAvaliacaoModelo.ativo"
                                          @change="alteraAtivacaoModelo()"
                                          type="checkbox"
                                        >
                                        <span class="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                            <label class="d-block">Emails</label>
                            <b-button-group>
                              <b-dropdown
                                right
                                split
                                text="Personalizar"
                              >
                                <b-dropdown-item
                                  v-if="fastEmailAvaliacao.id_email"
                                  @click.prevent="showModal('modalEditarEmailAvaliacao')"
                                >
                                  Email genérico de avaliação disponível
                                </b-dropdown-item>
                                <b-dropdown-item
                                  v-if="fastEmailRecuperacao.id_email"
                                  @click.prevent="showModal('modalEditarEmailRecuperacao')"
                                >
                                  Email genérico de recuperação disponível
                                </b-dropdown-item>
                                <b-dropdown-item
                                  v-if="fastEmailAprovacao.id_email"
                                  @click.prevent="showModal('modalEditarEmailAprovacao')"
                                >
                                  Email genérico de aprovação
                                </b-dropdown-item>

                                <b-dropdown-item
                                  v-if="!fastEmailAprovacao.id_email && !fastEmailRecuperacao.id_email && !fastEmailAprovacao.id_email"
                                >
                                  <b-icon icon="gear-fill" animation="spin"/> Carregando emails
                                </b-dropdown-item>

                              </b-dropdown>
                            </b-button-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>  

              </div>
              <div class="row">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirMatriz"
                    class="col-12 mt-2"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Matriz curricular
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              v-if="!fastCursoPossuiTurmas"
                              class="btn btn-sm btn-primary"
                              @click.prevent="showModal('modalMeusCursos')"
                            >
                              <small>+ Adicionar unidade curricular</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Sequência</small>
                                  </th>
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Unidade curricular</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Carga horária</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Preço</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Créditos</small>
                                  </th>
                                  <th class="text-center">
                                    <small
                                      class="font-weight-bold"
                                    >Pré-requisito</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastCursosProgramasLoading">
                                <tr>
                                  <td
                                    colspan="7"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastCursosProgramas.length">
                                <tr
                                  v-for="curso in fastCursosProgramas"
                                  :key="curso.id_secretaria_curso_programa_unidade"
                                >
                                  <td class="align-middle">
                                    <input
                                      v-model="curso.sequencia"
                                      style="width: 70px"
                                      type="number"
                                      min="1"
                                      class="form-control form-control-sm"
                                      @change="corrigeSequenciaCurso(curso)"
                                    >
                                  </td>
                                  <td class="align-middle">
                                    <small>
                                      <a
                                        :href="
                                          '/plataforma/' +
                                            $route.params.id_plataforma +
                                            '/secretaria/unidade-curricular/' +
                                            curso.id_curso
                                        "
                                      >
                                      <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                                      <span v-else>{{ curso.nome_curso_mae }}</span>
                                      </a>
                                    </small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.carga_horaria">{{ curso.carga_horaria }} h</small>
                                    <small v-else>{{ curso.carga_horaria_mae }} h</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.preco">R$ {{ formataPreco(curso.preco) }}</small>
                                    <small
                                      v-else
                                      class="text-danger"
                                    >Indefinido</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <small v-if="curso.creditos">{{ curso.creditos }}</small>
                                    <small
                                      v-else
                                      class="text-danger"
                                    >Nenhum</small>
                                  </td>
                                  <td class="text-center align-middle">
                                    <div
                                      v-for="curso2 in fastCursosProgramas"
                                      :key="curso2.id_secretaria_curso_programa_unidade"
                                    >
                                      <small v-if="curso2.id_secretaria_curso_programa_unidade == curso.id_pai">{{ curso2.nome_curso }}</small>
                                    </div>
                                  </td>
                                  <td class="text-center align-middle">
                                    
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 btn-block"
                                      @click="
                                        exibeModalEditarProgramaCurso(curso)
                                      "
                                    >
                                      <small>Editar</small>
                                    </button>
                                    <button
                                      v-if="curso.id_secretaria_avaliacao_uc"
                                      :class="curso.id_secretaria_avaliacao_principal ? 'btn btn-sm btn-success pt-0 pb-0 btn-block mt-1' : 'btn btn-sm btn-secondary pt-0 pb-0 btn-block mt-1'"
                                      @click="exibeAvaliacao(curso)"
                                    >
                                      <small>Avaliação</small>
                                    </button>
                                    <button
                                      v-if="curso.id_secretaria_avaliacao_uc"
                                      :class="curso.id_secretaria_avaliacao_recuperacao ? 'btn btn-sm btn-success pt-0 pb-0 btn-block mt-1' : 'btn btn-sm btn-secondary pt-0 pb-0 btn-block mt-1'"
                                      @click="exibeRecuperacao(curso)"
                                    >
                                      <small>Recuperação</small>
                                    </button>
                                    <button
                                      class="btn btn-sm btn-secondary pt-0 pb-0 btn-block mt-1"
                                      @click="exibePlanoEnsino(curso)"
                                    >
                                      <small>Plano de ensino <b-icon
                                        v-if="fastSecretariaCurso.id_base_curricular && !curso.id_plano_ensino"
                                        icon="exclamation-circle-fill"
                                        variant="warning"
                                      /></small>
                                    </button>
                                    <button
                                      v-if="!fastCursoPossuiTurmas"
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2 btn-block"
                                      @click="
                                        exibeModalExcluirProgramaCurso(curso)
                                      "
                                    >
                                      <small>Excluir</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="7"
                                    class="text-center"
                                  >
                                    Nenhuma unidade curricular cadastrada
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              v-if="fastProgramaCursoEditar.alteracao_pendente"
                              class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                            >
                              <button
                                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                @click.prevent="atualizaSequenciaCurso()"
                              >
                                <small>
                                  <i
                                    class="fa fa-floppy-o"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Salvar alterações
                                </small>
                              </button>
                              <button
                                class="btn btn-secondary btn-sm mt-2 ml-2"
                                @click.prevent="getFastCursosPrograma()"
                              >
                                <small>
                                  <i
                                    class="fa fa-ban"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Cancelar
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirUnidade"
                    class="col-12 mt-2"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              UC - {{ fastUnidadeCurricular.nome_curso }} - Plano de ensino
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">                          
                          <div
                            v-if="fastSecretariaCurso.id_base_curricular"
                            class="col-sm-12 col-md-12 col-lg-12 mb-4"
                          >                            
                            <label class="font-weight-bold">Componentes associados</label>
                            <div class="row">
                              <div class="col-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalExibirBaseCurricular')"
                                >
                                  <small> + Componente da base curricular</small>
                                </button>
                              </div>
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm table-bordered">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th><small class="font-weight-bold">Sequência do componente</small></th>
                                      <th><small class="font-weight-bold">Nome do componente</small></th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastComponentesAssociadosLoading">
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                        Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastComponentesAssociados.length">
                                    <tr
                                      v-for="(e, index) in fastComponentesAssociados"
                                      :key="index"
                                    >
                                      <td class="align-middle text-center">
                                        <small>{{ e.sequencia }}</small>                                  
                                      </td>
                                      <td class="align-middle text-center">
                                        <small>{{ e.unidade_curricular_tematica }}</small>                                  
                                      </td>                               
                                      <td class="text-center align-middle">  
                                        <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 ml-1 mr-1"
                                          @click="exibeModalExcluirComponenteAssociado(e)"
                                        >
                                          <small>- Remover associação</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                        <small>Nenhum componentes cadastrado</small>                                        
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">                  
                            <label class="font-weight-bold">Competências</label>
                            <div
                              v-if="fastSecretariaCurso.id_base_curricular"
                              class="row mt-0"
                            >
                              <div class="col-12 text-right">
                                <button class="btn btn-sm btn-primary d-none">
                                  <small>+ Adicionar competência</small>
                                </button>
                              </div>
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm table-bordered">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th><small class="font-weight-bold">Competência</small></th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastComponente.competencias.loading">
                                    <tr>
                                      <td
                                        colspan="1"
                                        class="text-center"
                                      >
                                        Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastComponente.competencias.total.length">
                                    <tr
                                      v-for="(e, index) in fastComponente.competencias.total"
                                      :key="index"
                                    >
                                      <td class="align-middle">
                                        <small>{{ e.nome_competencia }}</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="1"
                                        class="text-center"
                                      >
                                        <small>Nenhuma competência associada</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="col-12">
                                <label class="font-weight-bold">Detalhes da competência</label>
                              </div>
                            </div>
                            <div>                              
                              <div
                                v-if="fastComponente.competencias.loading"
                                class="text-center mt-2"
                              >
                                <b-icon
                                  icon="gear-fill"
                                  animation="spin"
                                /> Carregando competências
                              </div>
                              <quill-editor
                                v-else
                                v-model="fastPlanoEnsino.desc_competencia"
                                :options="editorOption"
                                @focus="fastAlteracoesPendentes.desc_competencia = true"
                              />
                              <div
                                v-if="fastAlteracoesPendentes.desc_competencia"
                                class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                              >
                                <button
                                  class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                  @click="insereFastPlanoEnsino()"
                                >
                                  <small>
                                    <i
                                      class="fa fa-floppy-o"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Salvar alterações
                                  </small>
                                </button>
                              </div>
                            </div>
                          </div>                          
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <label class="font-weight-bold">Indicadores</label>
                            <div
                              v-if="fastSecretariaCurso.id_base_curricular"
                              class="row mt-0"
                            >
                              <div class="col-12 text-right d-none">
                                <button class="btn btn-sm btn-primary">
                                  <small>+ Adicionar indicador</small>
                                </button>
                              </div>
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm table-bordered">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th><small class="font-weight-bold">Indicadores</small></th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastComponente.indicadores.loading">
                                    <tr>
                                      <td
                                        colspan="1"
                                        class="text-center"
                                      >
                                        Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastComponente.indicadores.total.length">
                                    <tr
                                      v-for="(e, index) in fastComponente.indicadores.total"
                                      :key="index"
                                    >
                                      <td class="align-middle">
                                        <small>{{ e.nome_indicador }}</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="1"
                                        class="text-center"
                                      >
                                        <small>Nenhum indicador associado</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="col-12">
                                <label class="font-weight-bold">Detalhes dos indicadores</label>
                              </div>
                            </div>
                            <div>
                              <div
                                v-if="fastComponente.indicadores.loading"
                                class="text-center mt-2"
                              >
                                <b-icon
                                  icon="gear-fill"
                                  animation="spin"
                                /> Carregando indicadores
                              </div>
                              <quill-editor
                                v-else
                                v-model="fastPlanoEnsino.desc_indicadores"
                                :options="editorOption"
                                @focus="fastAlteracoesPendentes.desc_indicadores = true"
                              />
                              <div
                                v-if="fastAlteracoesPendentes.desc_indicadores"
                                class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                              >
                                <button
                                  class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                  @click="insereFastPlanoEnsino()"
                                >
                                  <small>
                                    <i
                                      class="fa fa-floppy-o"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Salvar alterações
                                  </small>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <label class="font-weight-bold">Bibliografias</label>
                            <div
                              v-if="fastSecretariaCurso.id_base_curricular"
                              class="row mt-0"
                            >
                              <div class="col-12 text-right d-none">
                                <button class="btn btn-sm btn-primary">
                                  <small>+ Adicionar bibliografia</small>
                                </button>
                              </div>
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th class="text-center">
                                        <small class="font-weight-bold">ISBN</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Nome do livro</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Autor(a)</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">ano</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastComponente.bibliografias.loading">
                                    <tr>
                                      <td
                                        colspan="4"
                                        class="text-center"
                                      >
                                        Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastComponente.bibliografias.total.length">
                                    <tr
                                      v-for="(e, index) in fastComponente.bibliografias.total"
                                      :key="index"
                                    >
                                      <td class="align-middle text-center">
                                        <small v-if="e.isbn">
                                          <span v-if="e.isbn.trim()">{{ e.isbn }}</span>
                                          <span v-else>Não registrado</span>
                                        </small>
                                        <small v-else>Não registrado</small>
                                      </td>
                                      <td class="align-middle text-center">
                                        <small>{{ e.nome_livro }}</small>
                                      </td>
                                      <td class="align-middle text-center">
                                        <small>{{ e.autora }}</small>
                                      </td>
                                      <td class="align-middle text-center">
                                        <small>{{ e.ano }}</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="4"
                                        class="text-center"
                                      >
                                        <small>Nenhuma bibliografia associada</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="col-12">
                                <label class="font-weight-bold">Detalhes da bibliografia</label>
                              </div>
                            </div>
                            <div>
                              <div
                                v-if="fastComponente.bibliografias.loading"
                                class="text-center mt-2"
                              >
                                <b-icon
                                  icon="gear-fill"
                                  animation="spin"
                                /> Carregando bibliografias
                              </div>
                              <quill-editor
                                v-else
                                v-model="fastPlanoEnsino.desc_bibliografia"
                                :options="editorOption"
                                @focus="fastAlteracoesPendentes.desc_bibliografia = true"
                              />
                              <div
                                v-if="fastAlteracoesPendentes.desc_bibliografia"
                                class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                              >
                                <button
                                  class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                  @click="insereFastPlanoEnsino()"
                                >
                                  <small>
                                    <i
                                      class="fa fa-floppy-o"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Salvar alterações
                                  </small>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <label class="font-weight-bold">Objeto de conhecimento</label>
                            <quill-editor
                              v-model="fastPlanoEnsino.desc_objeto_conhecimento"
                              :options="editorOption"
                              @focus="fastAlteracoesPendentes.desc_objeto_conhecimento = true"
                            />
                            <div
                              v-if="fastAlteracoesPendentes.desc_objeto_conhecimento"
                              class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                            >
                              <button
                                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                @click="insereFastPlanoEnsino()"
                              >
                                <small>
                                  <i
                                    class="fa fa-floppy-o"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Salvar alterações
                                </small>
                              </button>
                            </div>
                          </div>  
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <label class="font-weight-bold">Habilidades</label>
                            <quill-editor
                              v-model="fastPlanoEnsino.desc_habilidade"
                              :options="editorOption"
                              @focus="fastAlteracoesPendentes.desc_habilidade = true"
                            />
                            <div
                              v-if="fastAlteracoesPendentes.desc_habilidade"
                              class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                              @click="insereFastPlanoEnsino()"
                            >
                              <button
                                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                              >
                                <small>
                                  <i
                                    class="fa fa-floppy-o"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Salvar alterações
                                </small>
                              </button>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <label class="font-weight-bold">Metodologias</label>
                            <quill-editor
                              v-model="fastPlanoEnsino.desc_metodologia"
                              :options="editorOption"
                              @focus="fastAlteracoesPendentes.desc_metodologia = true"
                            />
                            <div
                              v-if="fastAlteracoesPendentes.desc_metodologia"
                              class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                            >
                              <button
                                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                @click="insereFastPlanoEnsino()"
                              >
                                <small>
                                  <i
                                    class="fa fa-floppy-o"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Salvar alterações
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click="alteraFastNavegacao('exibirMatriz')"
                            >
                              Voltar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="row">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirAvaliacao"
                    class="col-12 mt-2"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              UC - {{ fastUnidadeCurricular.nome_curso }} - Avaliação
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <div class="card mb-4 border-0">
                                  <div class="card-body">
                                    <div class="col-12">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                          <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                              <div class="fd-app-curso-aulas">
                                                <div class="fd-app-curso-aulas-list mt-0">
                                                  <div
                                                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                                  >
                                                    <div
                                                      class="col-sm-12 col-md-12 col-lg-12 mb-3"
                                                    >
                                                      <div class="col-12 text-center mb-1">
                                                        <span>Habilitar avaliação nessa UC</span>
                                                      </div>
                                                      <div class="col-12 text-center">
                                                        <label class="switch">
                                                          <input
                                                            v-model="
                                                              fastModeloUcs.uc.avaliacao_configurada
                                                            "
                                                            @change="editaAvaliacaoUc()"
                                                            type="checkbox"
                                                          >
                                                          <span class="slider round" />
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> 
                                            <div v-if="fastModeloUcs.uc.avaliacao_configurada" class="col-sm-12 col-md-12 col-lg-12">
                                              <div class="row">
                                                <div class="col-12">
                                                  <label class="font-weight-bold">Configuração de avaliação</label>
                                                </div>                            
                                              </div>
                                              <div class="row">
                                                <div class="col-12">
                                                  <select class="form-control" @change="alteraPeriodoAvaliacao" ref="selPeriodoAvaliacao">
                                                    <option value="">-- Selecione a data da avaliação --</option>
                                                    <option value="F">Ao final do módulo</option>
                                                    <option value="D">Dias após início UC</option>
                                                  </select>
                                                </div>
                                                <div v-if="!fastAvaliacaoConfiguracao.final_modulo" class="col-12 mt-2">
                                                  <div class="form-check form-check-inline">
                                                    <input v-model="fastAvaliacaoConfiguracao.liberar_prazo" type="number" class="form-control mr-2" @blur="editaAvaliacaoConfiguracao()"> dias
                                                  </div>                                      
                                                </div>
                                              </div>  
                                              <div class="row mt-4">
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <label class="font-weight-bold">Avaliação por nota</label>
                                                    <div class="col-12 table-responsive mt-2">
                                                      <table class="table table-sm">
                                                        <tr>
                                                          <td>Nota máxima</td>
                                                          <td>
                                                            <input type="text" class="form-control" v-model="fastAvaliacaoConfiguracao.nota_maxima" @blur="editaAvaliacaoConfiguracao()">
                                                          </td>
                                                          <td></td>
                                                        </tr>
                                                        <tr>
                                                          <td>Aprovação</td>
                                                          <td>
                                                            <input type="text" class="form-control" v-model="fastAvaliacaoConfiguracao.media_aprovacao" @blur="editaAvaliacaoConfiguracao()">
                                                          </td>
                                                          <td>
                                                            <select class="form-control" v-model="fastAvaliacaoConfiguracao.id_resultado_media_aprovacao" @change="editaAvaliacaoConfiguracao()">
                                                              <option value="">-- Resultado --</option>
                                                              <option v-for="(e, index) in fastResultados.total" :key="index" :value="e.id_tipo_resultado_aluno">
                                                                {{e.nome_tipo_resultado}}
                                                              </option>
                                                            </select>
                                                          </td>
                                                          
                                                        </tr>
                                                      </table>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <label class="font-weight-bold">Arredondamento de notas</label>
                                                  <button
                                                    v-if="fastAvaliacaoConfiguracao.id_avaliacao_configuracao"
                                                    class="btn btn-sm btn-primary ml-2"
                                                    @click.prevent="showModal('modalCriarAvaliacaoArredondamento')"
                                                  >
                                                    <small>+ Adicionar</small>
                                                  </button>
                                                    <div class="col-12 table-responsive mt-2">
                                                      <table class="table table-sm">
                                                        <tr>
                                                          <th>Faixa inicial</th>
                                                          <th>Faixa final</th>
                                                          <th>Faixa nota</th>
                                                          <th>Ações</th>
                                                        </tr>
                                                        <tr v-for="(e, index) in fastAvaliacaoConfiguracaoArredondamentos" :key="index">
                                                          <td>{{adicionaCasaDecimal(e.faixa_inicial)}}</td>
                                                          <td>{{adicionaCasaDecimal(e.faixa_final)}}</td>
                                                          <td>{{adicionaCasaDecimal(e.nota)}}</td>
                                                          <td>
                                                            <button
                                                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                                              @click="exibeModalEditarAvaliacaoArredondamento('A', e)"
                                                            >
                                                              <small>Editar</small>
                                                            </button>                                                
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                </div>
                                              </div>
                                              <div v-if="fastSecretariaAvaliacao.id_secretaria_avaliacao" class="row mt-4" >
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Título da avaliação</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaAvaliacao.titulo_avaliacao"
                                                        @blur="editaSecretariaAvaliacao('A')"
                                                        type="text"
                                                        class="form-control"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-12 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Detalhes da avaliação</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <textarea
                                                        v-model="fastSecretariaAvaliacao.detalhe_avaliacao"
                                                        @blur="editaSecretariaAvaliacao('A')"
                                                        class="form-control"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Tentativas máxima</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaAvaliacao.tentativas_maxima"
                                                        @blur="editaSecretariaAvaliacao('A')"
                                                        type="number"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Tempo limite</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaAvaliacao.tempo_limite"
                                                        @blur="editaSecretariaAvaliacao('A')"
                                                        type="time"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                                      <label class="h6 mt-4">Randomizar ordem</label>
                                                      <div class="fd-app-curso-aulas mt-0">
                                                      <div class="fd-app-curso-aulas-list mt-0">
                                                        <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <div class="col-12 text-center">
                                                              <label class="switch">
                                                                <input
                                                                  @change="editaSecretariaAvaliacao('A')"
                                                                  v-model="fastSecretariaAvaliacao.randomizar_ordem"
                                                                  type="checkbox"
                                                                >
                                                                <span class="slider round" />
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div> 
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Limite de questões</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaAvaliacao.limite_questoes"
                                                        @blur="editaSecretariaAvaliacao('A')"
                                                        type="number"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  v-if="fastModeloUcs.uc.id_secretaria_avaliacao_principal"
                                                  class="col-12 mt-4"
                                                >
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                                                      <h4 class="aluno_font_color">
                                                        Questões da Avaliação ({{fastSecretariaAvaliacaoQuestoes.length}})
                                                      </h4>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                                                      <a
                                                        class="btn btn-primary btn-sm mt-2 ml-2"
                                                        :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?id_secretaria_avaliacao=' + fastModeloUcs.uc.id_secretaria_avaliacao_principal + '&id_secretaria_avaliacao_uc=' + fastModeloUcs.uc.id_secretaria_avaliacao_uc + '&id_avaliacao_modelo=' + fastModeloUcs.uc.id_avaliacao_modelo + '&id_secretaria_curso=' + $route.params.id_secretaria_curso + '&id_secretaria_curso_programa=' + $route.params.id_secretaria_curso_programa + '&tipo=A'"
                                                      >
                                                        <small>
                                                          <i
                                                            class="fa fa-question-circle"
                                                            aria-hidden="true"
                                                          />
                                                          &nbsp;Gerenciar questões
                                                        </small>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="row pl-4 pr-4 pb-4 mt-2">
                                                    <div class="col-12 mt-2 pl-4 pr-4">
                                                      <div
                                                        class="progress"
                                                        style="height: 50px"
                                                      >
                                                        <div
                                                          :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                                          role="progressbar"
                                                          :aria-valuenow="{ width: fastSecretariaAvaliacaoPorcentagem }"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                          :style="{ width: fastSecretariaAvaliacaoPorcentagem + '%' }"
                                                        >
                                                          <h6 class="m-0">
                                                            Avaliação 
                                                          </h6>
                                                          <h6 class="m-0">
                                                            Peso:
                                                            <span>{{ fastSecretariaAvaliacaoQuestoesPeso }}</span>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    
                                                    <div
                                                      v-if="fastSecretariaAvaliacaoQuestoes.length"
                                                      class="col-12"
                                                    >
                                                      <div class="row pl-4 pr-4 pb-4">
                                                        <div class="col-12">
                                                          <div class="row pb-4">
                                                            <carousel
                                                              :navigation-enabled="true"
                                                              :per-page="1"
                                                              :mouse-drag="false"
                                                              :adjustable-height="true"
                                                              :pagination-size="20"
                                                              :resistance-coef="40"
                                                              class="col-12 shadow-lg p-2 mb-2 bg-white rounded"
                                                            >
                                                              <slide
                                                                v-for="(q, index) in fastSecretariaAvaliacaoQuestoes"
                                                                :key="q.id_questao"
                                                              >
                                                                <div class="row p-4">
                                                                  <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                                                    <h5>
                                                                      Questão #{{q.id_questao}} - {{ index + 1 }} de
                                                                      {{ fastSecretariaAvaliacaoQuestoes.length }}
                                                                    </h5>
                                                                  </div>
                                                                  <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                                                    <div class="row">
                                                                      <div class="col-sm-12 col-md-12 col-lg-7 text-right">
                                                                        <h5>Peso da questão</h5>
                                                                      </div>
                                                                      <div class="col-sm-12 col-md-12 col-lg-5 pl-0">
                                                                        <input
                                                                          v-model="q.questao_peso"
                                                                          type="number"
                                                                          step=".01"
                                                                          class="form-control text-center"
                                                                          @change="atualizaPesoQuestaoAvaliacao(q, 'A')"
                                                                        >
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="col-sm-12 col-md-12 col-lg-3 mt-2 text-right"
                                                                  >
                                                                    <button
                                                                      class="btn btn-sm btn-danger"
                                                                      @click="exibeModalExcluirQuestaoAvaliacao(q, 'A')"
                                                                    >
                                                                      <small class="pt-0 pb-0">Excluir da avaliação</small>
                                                                    </button>
                                                                  </div>

                                                                  <div class="col-12 mt-4">
                                                                    <div v-html="q.pergunta" />
                                                                  </div>
                                                                  <div
                                                                    v-if="q.alternativas.length"
                                                                    class="col-12 p-4"
                                                                  >
                                                                    <div
                                                                      v-for="(a) in q.alternativas"
                                                                      :key="a.id_alternativa"
                                                                      class="row pl-2 pr-2"
                                                                    >
                                                                      <div :class="'col-12 alt-correta-' + a.correta">
                                                                        <div class="row">
                                                                          <div class="col-6 mt-2">
                                                                            <h6>Alternativa #{{ a.id_alternativa }}</h6>
                                                                          </div>
                                                                          <div class="col-12">
                                                                            <div v-html="a.alternativa" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </slide>
                                                            </carousel>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                              <div v-else class="row mt-4">
                                                <div class="col-12">
                                                  <button
                                                    class="btn btn-primary mr-2"
                                                    @click="showModal('modalCriarAvaliacaoTemplate')"
                                                  >
                                                    <small> <b-icon-list-check /> Novo template de avaliação</small>
                                                  </button>  
                                                  <button
                                                      class="btn btn-primary mr-2 d-none"
                                                      @click="showModal('modalBuscarAvaliacaoTemplate')"
                                                    >
                                                    <small><b-icon-search /> Buscar template de avaliação</small>
                                                  </button> 
                                                </div>                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>   
                                                                
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>                        
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click="alteraFastNavegacao('exibirMatriz')"
                            >
                              Voltar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirRecuperacao"
                    class="col-12 mt-2"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              UC - {{ fastUnidadeCurricular.nome_curso }} - Recuperação
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <div class="card mb-4 border-0">
                                  <div class="card-body">
                                    <div class="col-12">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                          <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                              <div class="fd-app-curso-aulas">
                                                <div class="fd-app-curso-aulas-list mt-0">
                                                  <div
                                                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                                  >
                                                    <div
                                                      class="col-sm-12 col-md-12 col-lg-12 mb-3"
                                                    >
                                                      <div class="col-12 text-center mb-1">
                                                        <span>Habilitar recuperação nessa UC</span>
                                                      </div>
                                                      <div class="col-12 text-center">
                                                        <label class="switch">
                                                          <input
                                                            v-model="
                                                              fastModeloUcs.uc.recuperacao_configurada
                                                            "
                                                            @change="editaAvaliacaoUc()"
                                                            type="checkbox"
                                                          >
                                                          <span class="slider round" />
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> 
                                            <div v-if="fastModeloUcs.uc.recuperacao_configurada" class="col-sm-12 col-md-12 col-lg-12">
                                              <div class="row">
                                                
                                                <div class="col-12">
                                                  <label class="font-weight-bold">Unidade curricular de recuperação</label>
                                                  
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div  class="col-sm-12 col-md-12 col-lg-12">
                                                  <a
                                                  v-if="fastModeloUcs.uc.id_curso_uc_recuperacao"
                                                  :href="
                                                    '/plataforma/' +
                                                      $route.params.id_plataforma +
                                                      '/admin/curso/' +
                                                      fastModeloUcs.uc.id_curso_uc_recuperacao
                                                  "
                                                >
                                                  <span>#{{fastModeloUcs.uc.id_curso_uc_recuperacao}} - </span>
                                                  <span v-if="fastModeloUcs.uc.titulo_curso_recuperacao">{{ fastModeloUcs.uc.titulo_curso_recuperacao }}</span>
                                                  <span v-else>{{ fastModeloUcs.uc.titulo_curso_recuperacao_mae }}</span>
                                                  </a>
                                                  <span v-else>
                                                    Nenhuma unidade curricular configurada
                                                  </span>
                                                
                                                  <button class="btn btn-sm btn-secondary ml-2" @click="showModal('modalExibirUnidadesCurriculares')"> <small>Alterar</small> </button>
                                                </div>
                                               
                                              </div>
                                              <div class="row mt-4">
                                                <div class="col-12">
                                                  <label class="font-weight-bold">Configuração de recuperação</label>
                                                </div>                            
                                              </div>
                                              <div class="row">
                                                <div class="col-12">
                                                  <select class="form-control" @change="alteraPeriodoRecuperacao" ref="selPeriodoRecuperacao">
                                                    <option value="">-- Selecione a data da recuperação --</option>
                                                    <option value="F">Ao final do módulo</option>
                                                    <option value="D">Dias após início UC</option>
                                                  </select>
                                                </div>
                                                <div v-if="!fastRecuperacaoConfiguracao.final_modulo" class="col-12 mt-2">
                                                  <div class="form-check form-check-inline">
                                                    <input v-model="fastRecuperacaoConfiguracao.liberar_prazo" type="number" class="form-control mr-2" @blur="editaRecuperacaoConfiguracao()"> dias
                                                  </div>                                      
                                                </div>
                                              </div>  
                                              <div class="row mt-4">
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <label class="font-weight-bold">Recuperação por nota</label>
                                                    <div class="col-12 table-responsive mt-2">
                                                      <table class="table table-sm">
                                                        <tr>
                                                          <td>Nota máxima</td>
                                                          <td>
                                                            <input type="text" class="form-control" v-model="fastRecuperacaoConfiguracao.nota_maxima" @blur="editaRecuperacaoConfiguracao()">
                                                          </td>
                                                          <td></td>
                                                        </tr>
                                                        <tr>
                                                          <td>Aprovação</td>
                                                          <td>
                                                            <input type="text" class="form-control" v-model="fastRecuperacaoConfiguracao.media_recuperacao" @blur="editaRecuperacaoConfiguracao()">
                                                          </td>
                                                          <td>
                                                            <select class="form-control" v-model="fastRecuperacaoConfiguracao.id_resultado_media_aprovacao" @change="editaRecuperacaoConfiguracao()">
                                                              <option value="">-- Resultado --</option>
                                                              <option v-for="(e, index) in fastResultados.total" :key="index" :value="e.id_tipo_resultado_aluno">
                                                                {{e.nome_tipo_resultado}}
                                                              </option>
                                                            </select>
                                                          </td>
                                                          
                                                        </tr>
                                                      </table>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <label class="font-weight-bold">Arredondamento de notas</label>
                                                  <button
                                                    v-if="fastRecuperacaoConfiguracao.id_avaliacao_configuracao"
                                                    class="btn btn-sm btn-primary ml-2"
                                                    @click.prevent="showModal('modalCriarRecuperacaoArredondamento')"
                                                  >
                                                    <small>+ Adicionar</small>
                                                  </button>
                                                    <div class="col-12 table-responsive mt-2">
                                                      <table class="table table-sm">
                                                        <tr>
                                                          <th>Faixa inicial</th>
                                                          <th>Faixa final</th>
                                                          <th>Faixa nota</th>
                                                          <th>Ações</th>
                                                        </tr>
                                                        <tr v-for="(e, index) in fastRecuperacaoConfiguracaoArredondamentos" :key="index">
                                                          <td>{{adicionaCasaDecimal(e.faixa_inicial)}}</td>
                                                          <td>{{adicionaCasaDecimal(e.faixa_final)}}</td>
                                                          <td>{{adicionaCasaDecimal(e.nota)}}</td>
                                                          <td>
                                                            <button
                                                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                                              @click="exibeModalEditarAvaliacaoArredondamento('R', e)"
                                                            >
                                                              <small>Editar</small>
                                                            </button>                                                
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                </div>
                                              </div>
                                              <div v-if="fastSecretariaRecuperacao.id_secretaria_avaliacao" class="row mt-4" >
                                                <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Título da avaliação</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaRecuperacao.titulo_avaliacao"
                                                        @blur="editaSecretariaAvaliacao('R')"
                                                        type="text"
                                                        class="form-control"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-12 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Detalhes da avaliação</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <textarea
                                                        v-model="fastSecretariaRecuperacao.detalhe_avaliacao"
                                                        @blur="editaSecretariaAvaliacao('R')"
                                                        class="form-control"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Tentativas máxima</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaRecuperacao.tentativas_maxima"
                                                        @blur="editaSecretariaAvaliacao('R')"
                                                        type="number"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Tempo limite</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaRecuperacao.tempo_limite"
                                                        @blur="editaSecretariaAvaliacao('R')"
                                                        type="time"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                                      <label class="h6 mt-4">Randomizar ordem</label>
                                                      <div class="fd-app-curso-aulas mt-0">
                                                      <div class="fd-app-curso-aulas-list mt-0">
                                                        <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                            <div class="col-12 text-center">
                                                              <label class="switch">
                                                                <input
                                                                  @change="editaSecretariaAvaliacao('R')"
                                                                  v-model="fastSecretariaRecuperacao.randomizar_ordem"
                                                                  type="checkbox"
                                                                >
                                                                <span class="slider round" />
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div> 
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <label class="h6 mt-4">Limite de questões</label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                      <input
                                                        v-model="fastSecretariaRecuperacao.limite_questoes"
                                                        @blur="editaSecretariaAvaliacao('R')"
                                                        type="number"
                                                        class="form-control text-center"
                                                      >
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  v-if="fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao"
                                                  class="col-12 mt-4"
                                                >
                                                  <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                                                      <h4 class="aluno_font_color">
                                                        Questões da Recuperação ({{fastSecretariaRecuperacaoQuestoes.length}})
                                                      </h4>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                                                      <a
                                                        class="btn btn-primary btn-sm mt-2 ml-2"
                                                        :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?id_secretaria_avaliacao=' + fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao + '&id_secretaria_avaliacao_uc=' + fastModeloUcs.uc.id_secretaria_avaliacao_uc + '&id_avaliacao_modelo=' + fastModeloUcs.uc.id_avaliacao_modelo + '&id_secretaria_curso=' + $route.params.id_secretaria_curso + '&id_secretaria_curso_programa=' + $route.params.id_secretaria_curso_programa + '&tipo=R'"
                                                      >
                                                        <small>
                                                          <i
                                                            class="fa fa-question-circle"
                                                            aria-hidden="true"
                                                          />
                                                          &nbsp;Gerenciar questões
                                                        </small>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  
                                                  
                                                  <div class="row pl-4 pr-4 pb-4 mt-2">
                                                    <div class="col-12 mt-2 pl-4 pr-4">
                                                      <div
                                                        class="progress"
                                                        style="height: 50px"
                                                      >
                                                        <div
                                                          :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                                          role="progressbar"
                                                          :aria-valuenow="{ width: fastSecretariaRecuperacaoPorcentagem }"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                          :style="{ width: fastSecretariaRecuperacaoPorcentagem + '%' }"
                                                        >
                                                          <h6 class="m-0">
                                                            Avaliação 
                                                          </h6>
                                                          <h6 class="m-0">
                                                            Peso:
                                                            <span>{{ fastSecretariaRecuperacaoQuestoesPeso }}</span>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    
                                                    <div
                                                      v-if="fastSecretariaRecuperacaoQuestoes.length"
                                                      class="col-12"
                                                    >
                                                      <div class="row pl-4 pr-4 pb-4">
                                                        <div class="col-12">
                                                          <div class="row pb-4">
                                                            <carousel
                                                              :navigation-enabled="true"
                                                              :per-page="1"
                                                              :mouse-drag="false"
                                                              :adjustable-height="true"
                                                              :pagination-size="20"
                                                              :resistance-coef="40"
                                                              class="col-12 shadow-lg p-2 mb-2 bg-white rounded"
                                                            >
                                                              <slide
                                                                v-for="(q, index) in fastSecretariaRecuperacaoQuestoes"
                                                                :key="q.id_questao"
                                                              >
                                                                <div class="row p-4">
                                                                  <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                                                    <h5>
                                                                      Questão #{{q.id_questao}} - {{ index + 1 }} de
                                                                      {{ fastSecretariaRecuperacaoQuestoes.length }}
                                                                    </h5>
                                                                  </div>
                                                                  <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                                                    <div class="row">
                                                                      <div class="col-sm-12 col-md-12 col-lg-7 text-right">
                                                                        <h5>Peso da questão</h5>
                                                                      </div>
                                                                      <div class="col-sm-12 col-md-12 col-lg-5 pl-0">
                                                                        <input
                                                                          v-model="q.questao_peso"
                                                                          type="number"
                                                                          step=".01"
                                                                          class="form-control text-center"
                                                                          @change="atualizaPesoQuestaoAvaliacao(q, 'R')"
                                                                        >
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    class="col-sm-12 col-md-12 col-lg-3 mt-2 text-right"
                                                                  >
                                                                    <button
                                                                      class="btn btn-sm btn-danger"
                                                                      @click="exibeModalExcluirQuestaoAvaliacao(q, 'R')"
                                                                    >
                                                                      <small class="pt-0 pb-0">Excluir da recuperação</small>
                                                                    </button>
                                                                  </div>

                                                                  <div class="col-12 mt-4">
                                                                    <div v-html="q.pergunta" />
                                                                  </div>
                                                                  <div
                                                                    v-if="q.alternativas.length"
                                                                    class="col-12 p-4"
                                                                  >
                                                                    <div
                                                                      v-for="(a) in q.alternativas"
                                                                      :key="a.id_alternativa"
                                                                      class="row pl-2 pr-2"
                                                                    >
                                                                      <div :class="'col-12 alt-correta-' + a.correta">
                                                                        <div class="row">
                                                                          <div class="col-6 mt-2">
                                                                            <h6>Alternativa #{{ a.id_alternativa }}</h6>
                                                                          </div>
                                                                          <div class="col-12">
                                                                            <div v-html="a.alternativa" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </slide>
                                                            </carousel>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                              <div v-else class="row mt-4">
                                                <div class="col-12">
                                                  <button
                                                    class="btn btn-primary mr-2"
                                                    @click="showModal('modalCriarRecuperacaoTemplate')"
                                                  >
                                                    <small> <b-icon-list-check /> Novo template de avaliação</small>
                                                  </button>  
                                                  <button
                                                      class="btn btn-primary mr-2 d-none"
                                                      @click="showModal('modalBuscarRecuperacaoTemplate')"
                                                    >
                                                    <small><b-icon-search /> Buscar template de avaliação</small>
                                                  </button> 
                                                </div>                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>   
                                                                
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>                        
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click="alteraFastNavegacao('exibirMatriz')"
                            >
                              Voltar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/curso/' +
                      $route.params.id_secretaria_curso
                  "
                  @click.prevent="$router.push('/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/curso/' +
                    $route.params.id_secretaria_curso)"                  
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalMeusCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Cursos disponíveis ({{ fastUnidadesCurriculares.filtro.length }})</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <h6>Lista de cursos</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraCursos"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Id
                    </th>
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Carga horária
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastUnidadesCurriculares.filtro.length">
                  <tr
                    v-for="curso in fastUnidadesCurriculares.paginacao"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      #{{ curso.id_curso }}
                    </td>
                    <td class="align-middle">
                      <a
                        :href="
                          '/plataforma/' +
                            $route.params.id_plataforma +
                            '/admin/curso/' +
                            curso.id_curso
                        "
                      >
                        <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                        <span v-else>{{ curso.nome_curso_mae }}</span>
                      </a>
                    </td>
                    <td class="align-middle text-center">
                      {{ curso.carga_horaria }}h
                    </td>
                    <td class="align-middle text-center">
                      <span
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                        @click="adicionaCursoPrograma(curso.id_curso)"
                      >
                        <small>+ Curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastUnidadesCurriculares.filtro"
                @changePage="fastUnidadesCurriculares.paginacao = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarProgramaCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar unidade curricular</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarProgramaCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Créditos</label>
              <input
                v-model="fastProgramaCursoEditar.creditos"
                class="form-control"
                type="number"
              >
            </div>
            <div
              v-if="fastCursosProgramas.length"
              class="col-sm-12 col-md-12 col-lg-4 mb-2"
            >
              <label>Pré-requisito</label>
              <select
                v-model="fastProgramaCursoEditar.id_pai"
                class="form-control"
              >
                <option value="">-- Nenhum pré-requisito --</option>
                <option
                  v-for="curso in fastCursosProgramas"
                  :key="curso.id_secretaria_curso_programa_unidade"
                  :value="curso.id_secretaria_curso_programa_unidade"
                >
                  <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                  <span v-else>{{ curso.nome_curso_mae }}</span>
                </option>
              </select>
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Preço da unidade curricular</label>
              <input
                v-model="fastProgramaCursoEditar.preco"
                type="number"
                step=".01"
                class="form-control"
              >
            </div>   
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarProgramaCurso()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirProgramaCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir unidade curricular do módulo?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirProgramaCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="excluirProgramaCurso()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirBaseCurricular"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Base curricular</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExibirBaseCurricular')"
          >
            <b-icon-arrow-return-left /> Fechar
          </a>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de componentes da base curricular
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                      <a
                        :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/base-curricular/' + fastSecretariaCurso.id_base_curricular + '/componentes'"
                        class="btn btn-sm btn-primary"                            
                      >
                        <small>Configurar componentes</small>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th><small class="font-weight-bold">Sequência</small></th>
                            <th><small class="font-weight-bold">Nome do componente</small></th>
                            <th><small class="font-weight-bold">Habilidades</small></th>
                            <th><small class="font-weight-bold" /></th>
                            <th><small class="font-weight-bold" /></th>
                            <th><small class="font-weight-bold" /></th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastComponentesLoading">
                          <tr>
                            <td
                              colspan="7"
                              class="text-center"
                              v-html="fastLoadingDados()"
                            />
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastComponentes.length">
                          <tr
                            v-for="(e, index) in fastComponentes"
                            :key="index"
                          >
                            <td class="align-middle text-center">
                              <small>{{ e.sequencia }}</small>                                  
                            </td>
                            <td class="align-middle">
                              <small>{{ e.unidade_curricular_tematica }}</small>                                  
                            </td>
                            <td class="align-middle">
                              <small>{{ e.habilidade }}</small>                                  
                            </td>
                            <td class="text-center align-middle"> 
                              <button
                                v-if="e.total_bibliografia"
                                class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                              >
                                <small>Bibliografia <span class="badge badge-light">{{ e.total_bibliografia }}</span></small>
                              </button>
                            </td>
                            <td class="text-center align-middle"> 
                              <button
                                v-if="e.total_competencia"
                                class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                                @click="exibeModalBaseCompetenciaAdicionada(e)"
                              >
                                <small>Competências <span class="badge badge-light">{{ e.total_competencia }}</span></small>
                              </button> 
                            </td>
                            <td class="text-center align-middle"> 
                              <button
                                v-if="e.total_indicador"
                                class="btn btn-sm btn-success pt-0 pb-0 d-block mx-auto mb-1"
                              >
                                <small>Indicadores <span class="badge badge-light">{{ e.total_indicador }}</span></small>
                              </button>
                            </td>
                            <td class="text-center align-middle">                                                
                              <button
                                class="btn btn-sm btn-primary pt-0 pb-0 d-block mx-auto mb-1"
                                @click="selecionaComponenteBaseCurricular(e)"
                              >
                                <small>+ Associar</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="7"
                              class="text-center"
                            >
                              Nenhum componentes cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalExibirBaseCurricular');">
                <b-icon-arrow-return-left /> Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirComponenteAssociado"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Remover componente do plano de ensino?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirComponenteAssociado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="excluirComponenteAssociado()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalBaseCompetenciaAdicionada"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-12 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalExibirBaseCurricular', 'modalBaseCompetenciaAdicionada')"
          >
            <b-icon-arrow-return-left /> Voltar
          </a>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de competências do componente
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr class="text-center">
                            <th><small class="font-weight-bold">Competência</small></th>
                          </tr>
                        </thead>
                        <tbody v-if="fastCompetenciaAdicionadaLoading">
                          <tr>
                            <td
                              colspan="1"
                              class="text-center"
                            >
                              Carregando...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastCompetenciaAdicionadaPaginacao.length">
                          <tr
                            v-for="(e, index) in fastCompetenciaAdicionadaPaginacao"
                            :key="index"
                          >
                            <td class="align-middle">
                              <small>{{ e.nome_competencia }}</small>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="1"
                              class="text-center"
                            >
                              Nenhuma competência cadastrada
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastCompetenciaAdicionadaFiltro"
                        @changePage="
                          fastCompetenciaAdicionadaPaginacao = $event
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarAvaliacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAvaliacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="insereAvaliacaoArredondamento('A')"
                >
                  Adicionar arredondamento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarAvaliacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarAvaliacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoEditar.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoEditar.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" readonly v-model="fastAvaliacaoArredondamentoEditar.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editaAvaliacaoArredondamento('A')"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-12 mt-4 text-right">
              <button class="btn btn-sm btn-danger" @click="excluiAvaliacaoArredondamento('A')">
                <small><b-icon-trash  /> Excluir</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarRecuperacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRecuperacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="insereAvaliacaoArredondamento('R')"
                >
                  Adicionar arredondamento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarRecuperacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarRecuperacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoEditar.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoEditar.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" readonly v-model="fastRecuperacaoArredondamentoEditar.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editaAvaliacaoArredondamento('R')"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-12 mt-4 text-right">
              <button class="btn btn-sm btn-danger" @click="excluiAvaliacaoArredondamento('R')">
                <small><b-icon-trash  /> Excluir</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarAvaliacaoTemplate"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo template de avaliação</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAvaliacaoTemplate')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row" >
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Título da avaliação</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaAvaliacao.titulo_avaliacao"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Detalhes da avaliação</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <textarea
                    v-model="fastSecretariaAvaliacao.detalhe_avaliacao"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Tentativas máxima</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaAvaliacao.tentativas_maxima"
                    type="number"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Tempo limite</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaAvaliacao.tempo_limite"
                    type="time"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <label class="h6 mt-4">Randomizar ordem</label>
                  <div class="fd-app-curso-aulas mt-0">
                  <div class="fd-app-curso-aulas-list mt-0">
                    <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="col-12 text-center">
                          <label class="switch">
                            <input
                              v-model="fastSecretariaAvaliacao.randomizar_ordem"
                              type="checkbox"
                            >
                            <span class="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Limite de questões</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaAvaliacao.limite_questoes"
                    type="number"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-12 text-center mt-4">
              <button
                class="btn btn-primary mt-2 ml-2"
                @click.prevent="insereSecretariaAvaliacao('A')"
              > <b-icon-list-check /> Criar avaliação
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarRecuperacaoTemplate"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo template de avaliação</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRecuperacaoTemplate')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row" >
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Título da avaliação</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaRecuperacao.titulo_avaliacao"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Detalhes da avaliação</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <textarea
                    v-model="fastSecretariaRecuperacao.detalhe_avaliacao"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Tentativas máxima</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaRecuperacao.tentativas_maxima"
                    type="number"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Tempo limite</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaRecuperacao.tempo_limite"
                    type="time"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                  <label class="h6 mt-4">Randomizar ordem</label>
                  <div class="fd-app-curso-aulas mt-0">
                  <div class="fd-app-curso-aulas-list mt-0">
                    <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div class="col-12 text-center">
                          <label class="switch">
                            <input
                              v-model="fastSecretariaRecuperacao.randomizar_ordem"
                              type="checkbox"
                            >
                            <span class="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label class="h6 mt-4">Limite de questões</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <input
                    v-model="fastSecretariaRecuperacao.limite_questoes"
                    type="number"
                    class="form-control text-center"
                  >
                </div>
              </div>
            </div>

            <div class="col-12 text-center mt-4">
              <button
                class="btn btn-primary mt-2 ml-2"
                @click.prevent="insereSecretariaAvaliacao('R')"
              > <b-icon-list-check /> Criar avaliação
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExibirUnidadesCurriculares"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Cursos disponíveis ({{ fastUnidadesCurriculares.filtro.length }})</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraCursos"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      Id
                    </th>
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Carga horária
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastUnidadesCurriculares.filtro.length">
                  <tr
                    v-for="curso in fastUnidadesCurriculares.paginacao"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      #{{ curso.id_curso }}
                    </td>
                    <td class="align-middle">
                      <a
                        :href="
                          '/plataforma/' +
                            $route.params.id_plataforma +
                            '/admin/curso/' +
                            curso.id_curso
                        "
                      >
                        <span v-if="curso.nome_curso">{{ curso.nome_curso }}</span>
                        <span v-else>{{ curso.nome_curso_mae }}</span>
                      </a>
                    </td>
                    <td class="align-middle text-center">
                      {{ curso.carga_horaria }}h
                    </td>
                    <td class="align-middle text-center">
                      <span
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                        @click="fastModeloUcs.uc.id_curso_uc_recuperacao = curso.id_curso; fastModeloUcs.uc.titulo_curso_recuperacao = curso.nome_curso; fastModeloUcs.uc.titulo_curso_recuperacao_mae = curso.nome_curso_mae; editaAvaliacaoUc()"
                      >
                        <small>Selecionar</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhuma curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastUnidadesCurriculares.filtro"
                @changePage="fastUnidadesCurriculares.paginacao = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailAvaliacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailAvaliacao.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailAvaliacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailAvaliacao.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailAvaliacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailAvaliacao.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailAvaliacao.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailAvaliacao.ativo"
                        :checked="fastEmailAvaliacao.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailAvaliacao.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailAvaliacao.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailAvaliacao.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailAvaliacao', 'modalEditarEmailAvaliacao')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>    
              <div class="col-sm-12 col-md-12 col-lg-3">
                <!-- Upload aqui -->
                <input
                  id="image_avaliacao"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageAvaliacao"
                >
                <label
                  class="custom-file-label text-left"
                  for="image_avaliacao"
                ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <!-- /Upload aqui -->
              </div>          
              <quill-editor
                v-model="fastEmailAvaliacao.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailAvaliacao)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailAvaliacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailAvaliacao', 'modalEditarHTMLEmailAvaliacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailAvaliacao.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailAvaliacao', 'modalEditarHTMLEmailAvaliacao')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailRecuperacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailRecuperacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailRecuperacao.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailRecuperacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailRecuperacao.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailRecuperacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailRecuperacao.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailRecuperacao.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailRecuperacao.ativo"
                        :checked="fastEmailRecuperacao.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailRecuperacao.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailRecuperacao.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailRecuperacao.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailRecuperacao', 'modalEditarEmailRecuperacao')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div> 
              <div class="col-sm-12 col-md-12 col-lg-3">
                <!-- Upload aqui -->
                <input
                  id="image_recuperacao"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageRecuperacao"
                >
                <label
                  class="custom-file-label text-left"
                  for="image_recuperacao"
                ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <!-- /Upload aqui -->
              </div>              
              <quill-editor
                v-model="fastEmailRecuperacao.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailRecuperacao)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailRecuperacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailRecuperacao', 'modalEditarHTMLEmailRecuperacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailRecuperacao.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailRecuperacao', 'modalEditarHTMLEmailRecuperacao')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailAprovacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailAprovacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailAprovacao.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailAprovacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailAprovacao.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailAprovacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailAprovacao.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailAprovacao.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailAprovacao.ativo"
                        :checked="fastEmailAprovacao.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailAprovacao.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailAprovacao.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailAprovacao.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailAprovacao', 'modalEditarEmailAprovacao')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>   
              <div class="col-sm-12 col-md-12 col-lg-3">
                <!-- Upload aqui -->
                <input
                  id="image_aprovacao"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageAprovacao"
                >
                <label
                  class="custom-file-label text-left"
                  for="image_aprovacao"
                ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <!-- /Upload aqui -->
              </div>               
              <quill-editor
                v-model="fastEmailAprovacao.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailAprovacao)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailAprovacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailAprovacao', 'modalEditarHTMLEmailAprovacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailAprovacao.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailAprovacao', 'modalEditarHTMLEmailAprovacao')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeInternoSecretariaCursoPrograma",
  components: {
    Pagination, quillEditor, Carousel, Slide
  },

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "100%",
      fastNavegacao: {
        exibirMatriz: true,
        exibirUnidade: false,
        exibirAvaliacao: false,
        exibirRecuperacao: false
      },
      fastSecretariaCurso: {
        id_secretaria_curso: 0,
        nome_curso: "...",
	      detalhe_curso: "",
	      publicado: "",
	      carga_horaria: "",
	      logo_curso: "",
	      id_plataforma: 0,
        creditos_total: "",
        nivel: "",
        preco_curso: "",
        venda_modulo: "",
        venda_unidade_curricular: "",
        gera_certificado_curso: "",
        id_base_curricular: 0,
        programas: [],
      },
      // Cursos
      fastUnidadesCurriculares: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      // Cursos do programa
      fastCursosProgramas: [],
      fastCursosProgramasLoading: true,
      fastProgramaCursoEditar: {
        id_secretaria_curso_programa_unidade: 0,
        id_secretaria_curso_programa: 0,
        sequencia: "",
        id_pai: "",
        creditos: "",
        id_curso: 0,
        preco: "",
        id_plano_ensino: 0,
        alteracao_pendente: false,
      },
      fastCursoPossuiTurmas: 1,
      // Plano de ensino
      fastUnidadeCurricular: {
        id_curso: 0,
        nome_curso: ""
      },
      fastComponentes: [],
      fastComponentesLoading: true,
      fastPlanoEnsino: {
        id_plano_ensino: 0,
        id_plataforma: this.$route.params.id_plataforma,
        desc_objeto_conhecimento: "",
        desc_habilidade: "",
        desc_competencia: "",
        desc_metodologia: "",
        desc_indicadores: "",
        desc_bibliografia: "",
        id_curso: 0,
        id_base_curricular_componente: "",
        id_usuario: "",
        data_registro: "",
        id_usuario_alteracao: "",
        data_ultima_modificacao: "",
        loading: true
      },
      fastComponente: {
        bibliografias: {
          total: [],
          loading: false
        },
        competencias: {
          total: [],
          loading: false
        },
        indicadores: {
          total: [],
          loading: false
        }
      },
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },
      fastComponentesAssociados: [],
      fastComponentesAssociadosLoading: true,
      fastAlteracoesPendentes: {
        desc_objeto_conhecimento: false,
        desc_habilidade: false,
        desc_competencia: false,
        desc_metodologia: false,
        desc_indicadores: false,
        desc_bibliografia: false
      },
      // Competencias
      fastCompetenciaAdicionadaTotal: [],
      fastCompetenciaAdicionadaFiltro: [],
      fastCompetenciaAdicionadaPaginacao: [],
      fastCompetenciaAdicionadaLoading: true,
      // Avaliações
      // Modelos
      fastAvaliacaoModelo: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true,
        novo: {
          id_avaliacao_modelo: 0,
          id_email_avaliacao_aprovador: 0,
          id_email_avaliacao_liberada: 0,
          id_email_avaliacao_recuperacao: 0,
          id_plataforma: this.$route.params.id_plataforma,
          id_secretaria_curso: 0,
          id_secretaria_curso_programa: this.$route.params.id_secretaria_curso_programa,
          nome_curso: "",
          publicado: false,
          sequencia: 0,
          sigla_programa: "",
          tem_recuperacao: false
        },
        ativo: false
      },  
      fastModeloUcs: {
        total: [],
        filtro: [],
        paginacao: [],
        uc: {
          avaliacao_configurada: "",
          creditos: "",
          detalhe_curso: "",
          id_avaliacao_modelo: "",
          id_curso_uc: "",
          id_curso_uc_recuperacao: "",
          id_pai: "",
          id_secretaria_avaliacao_principal: "",
          id_secretaria_avaliacao_recuperacao: "",
          id_secretaria_avaliacao_uc: "",
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_proximo_programa: "",
          id_secretaria_curso_proximo_uc: "",
          nome_curso: "",
          preco: "",
          recuperacao_configurada: "",
          sequencia: "",
          tipo_avaliacao: "",
          titulo_curso_recuperacao: ""
        },
        configuracoes: [],
        loading: true
      },
      fastAvaliacaoConfiguracao: {
        id_avaliacao_configuracao: 0,
        tipo: "A",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0      
      }, 
      fastRecuperacaoConfiguracao: {
        id_avaliacao_configuracao: 0,
        tipo: "R",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0                
      }, 
      fastAvaliacaoConfiguracaoArredondamentos: [],
      fastRecuperacaoConfiguracaoArredondamentos: [],
      fastAvaliacaoArredondamentoNovo: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastRecuperacaoArredondamentoNovo: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastAvaliacaoArredondamentoEditar: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastRecuperacaoArredondamentoEditar: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastSecretariaAvaliacao: {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '01:00',
        randomizar_ordem: false,
        limite_questoes: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastSecretariaRecuperacao: {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '01:00',
        randomizar_ordem: false,
        limite_questoes: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastSecretariaAvaliacaoQuestoes: [],
      fastSecretariaRecuperacaoQuestoes: [],
      fastSecretariaAvaliacaoPorcentagem: 0,
      fastSecretariaRecuperacaoPorcentagem: 0,
      fastSecretariaAvaliacaoQuestoesPeso: 0,
      fastSecretariaRecuperacaoQuestoesPeso: 0,
      // Tipo de resultado
      fastResultados: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      // Emails
      fastEmailAvaliacao: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
      fastEmailRecuperacao: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
      fastEmailAprovacao: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
    };
  },
  mounted: function () {
    if (
      this.$route.params.id_plataforma &&
      this.$route.params.id_secretaria_curso &&
      this.$route.params.id_secretaria_curso_programa
    ) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getFastSecretariaCursoProgramas();
            this.getFastCursosPrograma();
            this.getFastCursos();
            // Recupera modelos
            this.getAvaliacoesModelo(this.$route.params.id_plataforma)
            // Tipo de resultado
            this.getfastResultados(this.$route.params.id_plataforma)
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    // Cursos
    async getFastSecretariaCursoProgramas() {
      this.fastCursosProgramasLoading = true
      this.getFastSecretariaCurso().then(async (curso) => {
        this.fastSecretariaCurso = {
          id_secretaria_curso: curso.id_secretaria_curso,
          nome_curso: curso.nome_curso ? curso.nome_curso : curso.nome_curso_mae,
          detalhe_curso: curso.detalhe_curso,
          publicado: curso.publicado,
          carga_horaria: curso.carga_horaria,
          logo_curso: this.ajustaLinkApiAntiga(curso.logo_curso),
          id_plataforma: curso.id_plataforma,
          creditos_total: curso.creditos_total,
          nivel: curso.nivel,
          preco_curso: curso.preco,
          venda_modulo: curso.venda_modulo,
          venda_unidade_curricular: curso.venda_unidade_curricular,
          gera_certificado_curso: curso.gera_certificado,
          id_base_curricular: curso.id_base_curricular,
          id_base_curricular: curso.id_base_curricular,
          programasLoading: true,
          programas: [],
        }       
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_curso_programa/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_secretaria_curso=" +
              this.$route.params.id_secretaria_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {            
            this.fastSecretariaCurso.programas = obj;            
          }
          this.$store.state.fastCarregando = false;
          this.fastCursosProgramasLoading = false

          
          if (this.fastSecretariaCurso.id_base_curricular) this.getComponentes(this.fastSecretariaCurso.id_base_curricular);
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.fastCursosProgramasLoading = false
        }
      })
      .then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0")
          .then(json => {    
              let turmasCurso = 0    
              let obj = Array.from(json);  
              if (obj.length)  turmasCurso = obj.filter(e =>  e.id_secretaria_curso === this.fastSecretariaCurso.id_secretaria_curso) 
              this.fastCursoPossuiTurmas = turmasCurso.length              
          })
      })
    },
    async getFastSecretariaCurso() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_curso/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_secretaria_curso=" +
              this.$route.params.id_secretaria_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json[0]);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    // Cursos
    async getFastCursos() {
      this.fastUnidadesCurriculares.loading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma?id_usuario=0" +
            "&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        if (objPlataforma.length > 0) {
          this.fastUnidadesCurriculares.total = objPlataforma
          this.fastUnidadesCurriculares.filtro = objPlataforma
          this.fastMeusCursosFiltro = objPlataforma;
        }
        this.fastUnidadesCurriculares.loading = false
      } catch (e) {
        console.log("Erro", e);
        this.fastUnidadesCurriculares.loading = false
      }
    },
    filtraCursos(e) {
      let text = e.target.value;
      this.fastUnidadesCurriculares.filtro = this.fastUnidadesCurriculares.total.filter((e) => {
        if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
        else
          return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
      });
    },
    async adicionaCursoPrograma(id_curso) {
      let fast_secretaria_curso_programa_unidade = {
        id_secretaria_curso_programa: this.$route.params.id_secretaria_curso_programa,
        id_curso: id_curso,
      };
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa_unidade/insere",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_secretaria_curso_programa_unidade)
          )
        );

        let json = await resp.json();

        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastCursosPrograma();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Programa editado com sucesso",
        });
        this.hideModal("modalMeusCursos");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async getFastCursosPrograma() {
      this.fastProgramaCursoEditar.alteracao_pendente = false;
      this.fastCursosProgramas = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_curso_programa_unidade/lista?id_secretaria_curso_programa=" +
            this.$route.params.id_secretaria_curso_programa,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        
        if (obj.length > 0) {          
          this.fastCursosProgramas = obj;
          // Verifica se está voltando da prova
          if (this.getUrlParameter("id_secretaria_avaliacao_uc") && this.getUrlParameter("tipo")) {
              obj.forEach(e => {
                if (e.id_secretaria_avaliacao_uc == this.getUrlParameter("id_secretaria_avaliacao_uc")) {
                  switch (this.getUrlParameter("tipo")) {
                    case 'A':
                      this.exibeAvaliacao(e)
                      break;
                    case 'R':
                      this.exibeRecuperacao(e)
                      break;
                    default:
                      break;
                  }
                }
              })
          }
        } else {
          this.fastCursosProgramas = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEditarProgramaCurso(curso) {
      curso.alteracao_pendente = false;
      this.fastProgramaCursoEditar = curso;
      this.showModal("modalEditarProgramaCurso");
    },
    async editarProgramaCurso() {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa_unidade/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.fastProgramaCursoEditar)
          )
        );
        let json = await resp.json();
        console.log("editarProgramaCurso", json);
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastCursosPrograma();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso editado com sucesso",
        });
        this.fastProgramaCursoEditar = {
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_programa: 0,
          sequencia: "",
          id_pai: "",
          creditos: "",
          id_curso: 0,
          preco: "",
          alteracao_pendente: false,
        };
        this.hideModal("modalEditarProgramaCurso");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    exibeModalExcluirProgramaCurso(curso) {
      curso.alteracao_pendente = false;
      this.fastProgramaCursoEditar = curso;
      this.showModal("modalExcluirProgramaCurso");
    },
    async excluirProgramaCurso() {
      this.$store.state.fastCarregando = true;
      const fast_secretaria_curso_programa_unidade = { id_secretaria_curso_programa_unidade: this.fastProgramaCursoEditar.id_secretaria_curso_programa_unidade }

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa_unidade/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_secretaria_curso_programa_unidade)
          )
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastCursosPrograma();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso excluído com sucesso",
        });
        this.fastProgramaCursoEditar = {
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_programa: 0,
          sequencia: "",
          id_pai: "",
          creditos: "",
          id_curso: 0,
          preco: "",
          alteracao_pendente: false,
        };
        this.hideModal("modalExcluirProgramaCurso");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    corrigeSequenciaCurso(curso) {
      this.fastProgramaCursoEditar.alteracao_pendente = true;
      this.fastCursosProgramas.sort(this.reornedarArrayObjetos("sequencia"));
    },
    atualizaSequenciaCurso() {
      this.$store.state.fastCarregando = true;
      this.fastCursosProgramas.forEach((e, index) => {
        e.sequencia = index + 1;
        setTimeout(() => {
          this.salvaAtualizaSequenciaCurso(e, index);
        }, 2000);
      });
    },
    async salvaAtualizaSequenciaCurso(e, index) {  
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa_unidade/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.fastProgramaCursoEditar)
          )
        );

        let json = await resp.json();
        let obj = Array.from(json);

        if (this.fastCursosProgramas.length == index + 1) {
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Sequência atualizada com sucesso",
          });
          this.$store.state.fastCarregando = false;
          this.fastProgramaCursoEditar.alteracao_pendente = false;
        }
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao atualizar sequência",
        });
        console.log("Erro", e);
      }
    },
    // Plano de ensino
    exibePlanoEnsino(curso) {
      this.fastUnidadeCurricular.nome_curso = curso.nome_curso ? curso.nome_curso : curso.nome_curso_mae
      this.fastPlanoEnsino.desc_objeto_conhecimento = ""      
      this.fastPlanoEnsino.desc_competencia = ""
      this.fastPlanoEnsino.desc_indicadores = ""
      this.fastPlanoEnsino.desc_bibliografia = ""      
      this.fastPlanoEnsino.desc_habilidade = ""
      this.fastPlanoEnsino.desc_metodologia = ""
      this.fastPlanoEnsino.id_plano_ensino = curso.id_plano_ensino ? curso.id_plano_ensino : 0
      this.fastPlanoEnsino.id_curso = curso.id_curso      
      this.fastComponente.bibliografias.total = []
      this.fastComponente.competencias.total = []
      this.fastComponente.indicadores.total = []
      this.fastProgramaCursoEditar = curso;
      this.alteraFastNavegacao("exibirUnidade")

      if (this.fastPlanoEnsino.id_plano_ensino) {
        this.promiseGetFastApi("api/secretaria_plano_ensino/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        .then(res => {
          if (res.length) {
            this.fastPlanoEnsino = res[0]

            // Esconde botões de salvar alterações
            let fastAlteracoesPendentes = Object.assign({}, this.fastAlteracoesPendentes);
            const keys = Object.keys(fastAlteracoesPendentes)
            keys.forEach(k => this.fastAlteracoesPendentes[k] = false) 
          } else {
            this.exibeToasty("Erro ao recuperar plano de ensino", "error")
          }
        })
        .catch(e => this.exibeToasty(e, "error"))

      this.getComponenteAssociados()
      } else {
        this.exibeToasty("Nenhum plano de ensino está configurado", "info");
        this.fastComponentesAssociados = []
        this.fastComponentesAssociadosLoading = false        
      }
      
    },
    async getComponentes(id_base_curricular){
      this.fastComponentesLoading = true
      this.promiseGetFastApi("api/projeto_base_curricular_componente/lista", "id_base_curricular=" + id_base_curricular).then(obj => {
        if (obj.length) this.fastComponentes = obj
        else    
            this.fastComponentes = [];

        this.fastComponentesLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar componentes da curricular", "error");
        this.fastComponentesLoading = false
      })
    },
    async selecionaComponenteBaseCurricular(componente){
      //this.fastPlanoEnsino.desc_objeto_conhecimento += componente.unidade_curricular_tematica + "\n"
      this.hideModal('modalExibirBaseCurricular')

      // Insere/atualiza plano de estudo
      if (componente) this.fastPlanoEnsino.id_base_curricular_componente = componente.id_base_curricular_componente
      else this.fastPlanoEnsino.id_base_curricular_componente = 0
      const model = {
        secretaria_plano_ensino: this.fastPlanoEnsino,
        fast_secretaria_curso_programa_unidade: {
          id_secretaria_curso_programa_unidade: this.fastProgramaCursoEditar.id_secretaria_curso_programa_unidade
        }
      }


      this.promisePostFastApi(model, "api/secretaria_plano_ensino/insere")
      .then(res => {
        if (res.length) {
          this.fastPlanoEnsino = res[0]
          this.fastProgramaCursoEditar.id_plano_ensino = this.fastPlanoEnsino.id_plano_ensino

          // Esconde botões de salvar alterações
          let fastAlteracoesPendentes = Object.assign({}, this.fastAlteracoesPendentes);
          const keys = Object.keys(fastAlteracoesPendentes)
          keys.forEach(k => this.fastAlteracoesPendentes[k] = false) 

        } else {     
          this.exibeToasty("Erro ao cadastrar plano de ensino", "error");       
          return false
        }
      })
      .then(() => {
        this.getComponenteAssociados()
      })
      .catch(e => {
        this.exibeToasty("Erro ao cadastrar plano de ensino", "error");
      })
      
    },
    getComponenteAssociados(){
      this.fastComponentesAssociadosLoading = true
      this.fastComponente.bibliografias.loading = true
      this.fastComponente.competencias.loading = true
      this.fastComponente.indicadores.loading = true

      return new Promise(async (resolve, reject) => {
        // Lista componentes
        this.promiseGetFastApi("api/secretaria_plano_ensino/lista_componentes", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        // Lista bibliografias
        .then(res => {
          if (res.length) this.fastComponentesAssociados = res
          else this.fastComponentesAssociados = []          
          this.fastComponentesAssociadosLoading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_bibliografias", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        // Lista competências
        .then(res => {
          if (res.length) this.fastComponente.bibliografias.total = res
          else this.fastComponente.bibliografias.total = []
          this.fastComponente.bibliografias.loading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_competencias", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        // Lista indicadores
        .then(res => {
          if (res.length) this.fastComponente.competencias.total = res
          else this.fastComponente.competencias.total = []
          this.fastComponente.competencias.loading = false
          return this.promiseGetFastApi("api/secretaria_plano_ensino/lista_indicadores", `id_plataforma=${this.$route.params.id_plataforma}&id_plano_ensino=${this.fastPlanoEnsino.id_plano_ensino}`)
        })
        .then(res => {
          if (res.length) this.fastComponente.indicadores.total = res
          else this.fastComponente.indicadores.total = []
          this.fastComponente.indicadores.loading = false
          resolve(true)
        })
        .catch(e => {          
          reject("Erro ao cadastrar plano de ensino")
        })
      });
    },
    exibeModalExcluirComponenteAssociado(componente){
      this.fastProgramaCursoEditar = componente
      this.showModal('modalExcluirComponenteAssociado')
    },
    async excluirComponenteAssociado(){
      this.fastPlanoEnsino.id_base_curricular_componente = this.fastProgramaCursoEditar.id_base_curricular_componente
      const model = {
        secretaria_plano_ensino: {
          id_plataforma: this.$route.params.id_plataforma
        },
        secretaria_plano_ensino_componente: {
          id_plano_ensino: this.fastProgramaCursoEditar.id_plano_ensino,
          id_base_curricular_componente: this.fastProgramaCursoEditar.id_base_curricular_componente
        }
      }

      this.promisePostFastApi(model, "api/secretaria_plano_ensino/exclui_componente")
      .then(res => {
        this.getComponenteAssociados()
        this.hideModal('modalExcluirComponenteAssociado')
      })
      .catch(e => {
        this.exibeToasty("Erro ao remover componente", "error");
      })
    },
    async insereFastPlanoEnsino(){
      this.selecionaComponenteBaseCurricular()
    },
    // Competencias
    async exibeModalBaseCompetenciaAdicionada(componente){
      this.fastCompetenciaAdicionadaLoading = true
      this.showModal('modalBaseCompetenciaAdicionada', 'modalExibirBaseCurricular')
      this.promiseGetFastApi("api/projeto_base_curricular_componente_competencia/lista", "id_base_curricular_componente=" + componente.id_base_curricular_componente).then(obj => {
        if (obj.length) {
          this.fastCompetenciaAdicionadaTotal = obj
          this.fastCompetenciaAdicionadaFiltro = obj
        }
        else {
          this.fastCompetenciaAdicionadaTotal = []
          this.fastCompetenciaAdicionadaFiltro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastCompetenciaAdicionadaLoading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar competências", "error");
        this.$store.state.fastCarregando = false;
        this.fastCompetenciaAdicionadaLoading = false
    })
    },
    // Avaliações    
    async getAvaliacoesModelo(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_modelo/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso_programa=0&id_secretaria_curso=" + this.$route.params.id_secretaria_curso)
      .then(obj => {
        if (obj.length) {          
          this.fastAvaliacaoModelo.total = obj
          this.fastAvaliacaoModelo.filtro = obj
          obj.forEach(e => {
            if (e.id_secretaria_curso_programa == this.$route.params.id_secretaria_curso_programa) {
              this.fastAvaliacaoModelo.novo = e
              this.fastAvaliacaoModelo.ativo = true

              this.getFastPlataformaEmail(this.$route.params.id_plataforma, 'Avaliação disponível para aluno', 'Recuperação disponível para aluno', 'Aluno foi aprovado na unidade curricular')
            }
          })

        }
        else {
          this.fastAvaliacaoModelo.total = []
          this.fastAvaliacaoModelo.filtro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastAvaliacaoModelo.loading = false

      }).catch(e => { 
        this.exibeToasty("Erro ao buscar modelos de avaliação", "error");
        this.$store.state.fastCarregando = false;
        this.fastAvaliacaoModelo.loading = false
      })
    },
    async getModeloUcs(id_plataforma, id_avaliacao_modelo, id_secretaria_avaliacao_uc){
      this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc = 0
      this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc = 0
      
      this.fastModeloUcs.total = []
      this.fastModeloUcs.filtro = []
      if (id_avaliacao_modelo) {
        this.promiseGetFastApi("api/fast_secretaria_avaliacao_uc/lista", "id_plataforma=" + id_plataforma + "&id_avaliacao_modelo=" + id_avaliacao_modelo)
        .then(obj => {    
          console.log("api/fast_secretaria_avaliacao_uc/lista", obj)    
          if (obj.length) {
            obj.forEach(uc => {
              if (id_secretaria_avaliacao_uc == uc.id_secretaria_avaliacao_uc) {
                this.fastModeloUcs.uc = uc
                if (this.fastModeloUcs.uc.id_secretaria_avaliacao_principal) {
                  this.getSecretariaAvaliacao(this.$route.params.id_plataforma, this.fastModeloUcs.uc.id_secretaria_avaliacao_principal, 'A')
                }
                if (this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao) {
                  this.getSecretariaAvaliacao(this.$route.params.id_plataforma, this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao, 'R')                  
                }
              }
            })
            this.fastModeloUcs.total = obj
            this.fastModeloUcs.filtro = obj
          }
                
          this.$store.state.fastCarregando = false;
          this.fastModeloUcs.loading = false

          if (id_secretaria_avaliacao_uc) {
            this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc = id_secretaria_avaliacao_uc
            this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc = id_secretaria_avaliacao_uc
            this.getAvaliacaoConfiguracoes(id_secretaria_avaliacao_uc)
          }
        }).catch(e => { 
          this.exibeToasty("Erro ao buscar UCs do modelo de avaliação", "error");
          this.$store.state.fastCarregando = false;
          this.fastModeloUcs.loading = false
        })
      }
      
    },
    async getAvaliacaoConfiguracoes(id_secretaria_avaliacao_uc){      
      this.fastModeloUcs.configuracoes = []
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_configuracao/lista", "id_secretaria_avaliacao_uc=" + id_secretaria_avaliacao_uc)
      .then(obj => {    
        if (obj.length) {
          this.fastModeloUcs.configuracoes = obj
          obj.forEach(c => {
            if (!c.id_resultado_media_aprovacao) c.id_resultado_media_aprovacao = ""
            if (c.tipo == "A") {
              this.fastAvaliacaoConfiguracao = c
              if (this.$refs.selPeriodoAvaliacao) {
                if (this.fastAvaliacaoConfiguracao.final_modulo) this.$refs.selPeriodoAvaliacao.value = "F"
                else
                  this.$refs.selPeriodoAvaliacao.value = "D"
              }
              
            } else if (c.tipo == "R") {
              this.fastRecuperacaoConfiguracao = c
              if (this.$refs.selPeriodoRecuperacao) {
                if (this.fastRecuperacaoConfiguracao.final_modulo) this.$refs.selPeriodoRecuperacao.value = "F"
                else
                  this.$refs.selPeriodoRecuperacao.value = "D"
              }              
            }

            this.getAvaliacaoArredondamento(c)
            
            if (c.tipo == "A") this.fastAvaliacaoArredondamentoNovo.id_avaliacao_configuracao = c.id_avaliacao_configuracao 
            else
              this.fastRecuperacaoArredondamentoNovo.id_avaliacao_configuracao = c.id_avaliacao_configuracao 
          })
        }
        else {
          this.fastModeloUcs.configuracoes = []
        }            
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar configurações da avaliação", "error")
        console.log(e)
      })
    },
    async getAvaliacaoArredondamento(obj){      
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_arredondamento/lista", "id_avaliacao_configuracao=" + obj.id_avaliacao_configuracao)
      .then(res => {        
        
        if (res.length) {
          if (obj.tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res  
          }          
        }
        else {
          if (obj.tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = []  
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = []   
          }  
        }       
          
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar arredondamentos", "error")
        console.log(e)
      })
    },
    alteraAtivacaoModelo(){
      let acao = "exclui"
      if (this.fastAvaliacaoModelo.ativo) acao = "insere"

      if (!this.fastAvaliacaoModelo.novo.id_email_avaliacao_liberada) this.fastAvaliacaoModelo.novo.id_email_avaliacao_liberada = 0
      if (!this.fastAvaliacaoModelo.novo.id_email_avaliacao_recuperacao) this.fastAvaliacaoModelo.novo.id_email_avaliacao_recuperacao = 0
      if (!this.fastAvaliacaoModelo.novo.id_email_avaliacao_aprovador) this.fastAvaliacaoModelo.novo.id_email_avaliacao_aprovador = 0
      if (!this.fastAvaliacaoModelo.novo.tem_recuperacao) this.fastAvaliacaoModelo.novo.tem_recuperacao = false
      else
        this.fastAvaliacaoModelo.novo.tem_recuperacao = true

      this.promisePostFastApi(this.fastAvaliacaoModelo.novo, "api/fast_secretaria_avaliacao_modelo/" + acao)
      .then((res) => {

        if (res.ExceptionMessage) {
          

          if (acao == "insere") {
            this.exibeToasty("Não foi possível habilitar, a avaliação já está ativada", "error")
            this.fastAvaliacaoModelo.ativo = false
          } else {
            this.exibeToasty("Não foi possível desabilitar, já existem avaliações configuradas nas UCs", "error")
            this.fastAvaliacaoModelo.ativo = true
          }
          console.log(res.ExceptionMessage)
        } else {
          if (acao == "insere" && res.length) {
            this.exibeToasty("Avaliação habilitada sucesso", "success")
            this.getFastCursosPrograma()
            this.getAvaliacoesModelo(this.$route.params.id_plataforma)
          } else if (acao == "insere" && !res.length) {
            this.exibeToasty("Erro ao habilitar", "error")
            this.fastAvaliacaoModelo.ativo = false
          } else if (acao == "exclui" && !res.length){
            this.exibeToasty("Avaliação desabilitada sucesso", "success")
            this.getFastCursosPrograma()
            this.getAvaliacoesModelo(this.$route.params.id_plataforma)
          } else if (acao == "exclui" && res.length) {
            this.exibeToasty("Erro ao desabilitar", "error")
            this.fastAvaliacaoModelo.ativo = true
          }
        }
          
      }).catch((e) => {
          if (acao == "insere") {
            this.exibeToasty("Erro ao habilitar avaliação", "error")
            this.fastAvaliacaoModelo.ativo = false
          } else {
            this.exibeToasty("Erro ao desabilitar avaliação", "error")
            this.fastAvaliacaoModelo.ativo = true
          }
          
      });
    },
    exibeAvaliacao(curso) {
      this.fastModeloUcs = {
        total: [],
        filtro: [],
        paginacao: [],
        uc: {
          avaliacao_configurada: "",
          creditos: "",
          detalhe_curso: "",
          id_avaliacao_modelo: "",
          id_curso_uc: "",
          id_curso_uc_recuperacao: "",
          id_pai: "",
          id_secretaria_avaliacao_principal: 0,
          id_secretaria_avaliacao_recuperacao: 0,
          id_secretaria_avaliacao_uc: 0,
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_proximo_programa: "",
          id_secretaria_curso_proximo_uc: "",
          nome_curso: "",
          preco: "",
          recuperacao_configurada: "",
          sequencia: "",
          tipo_avaliacao: "",
          titulo_curso_recuperacao: ""
        },
        configuracoes: [],
        loading: true
      }

      this.fastAvaliacaoConfiguracao = {
        id_avaliacao_configuracao: 0,
        tipo: "A",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0      
      }
      this.fastSecretariaAvaliacao = {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '01:00',
        randomizar_ordem: false,
        limite_questoes: 0,
        id_plataforma: this.$route.params.id_plataforma
      }
      this.fastSecretariaAvaliacaoQuestoes = []
      this.fastSecretariaAvaliacaoPorcentagem = 0
      this.fastSecretariaAvaliacaoQuestoesPeso = 0
      this.fastAvaliacaoConfiguracaoArredondamentos = [] 

      this.fastUnidadeCurricular.nome_curso = curso.nome_curso ? curso.nome_curso : curso.nome_curso_mae
      this.alteraFastNavegacao("exibirAvaliacao")     
       
      this.getModeloUcs(this.$route.params.id_plataforma, curso.id_avaliacao_modelo, curso.id_secretaria_avaliacao_uc)
    },
    exibeRecuperacao(curso) {
      this.fastModeloUcs = {
        total: [],
        filtro: [],
        paginacao: [],
        uc: {
          avaliacao_configurada: "",
          creditos: "",
          detalhe_curso: "",
          id_avaliacao_modelo: "",
          id_curso_uc: "",
          id_curso_uc_recuperacao: "",
          id_pai: "",
          id_secretaria_avaliacao_principal: "",
          id_secretaria_avaliacao_recuperacao: "",
          id_secretaria_avaliacao_uc: "",
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_proximo_programa: "",
          id_secretaria_curso_proximo_uc: "",
          nome_curso: "",
          preco: "",
          recuperacao_configurada: "",
          sequencia: "",
          tipo_avaliacao: "",
          titulo_curso_recuperacao: ""
        },
        configuracoes: [],
        loading: true
      }
      this.fastRecuperacaoConfiguracao = {
        id_avaliacao_configuracao: 0,
        tipo: "A",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0      
      }
      this.fastSecretariaRecuperacao = {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '01:00',
        randomizar_ordem: "",
        limite_questoes: "",
        id_plataforma: this.$route.params.id_plataforma
      }
      this.fastSecretariaRecuperacaoQuestoes = []
      this.fastSecretariaRecuperacaoPorcentagem = 0
      this.fastSecretariaRecuperacaoQuestoesPeso = 0

      this.fastUnidadeCurricular.nome_curso = curso.nome_curso ? curso.nome_curso : curso.nome_curso_mae
      this.alteraFastNavegacao("exibirRecuperacao") 
      this.fastRecuperacaoConfiguracaoArredondamentos = []  
      this.getModeloUcs(this.$route.params.id_plataforma, curso.id_avaliacao_modelo, curso.id_secretaria_avaliacao_uc)  
    },
    editaAvaliacaoUc(){
      let erros = []
      if (!this.fastModeloUcs.uc.tipo_avaliacao) this.fastModeloUcs.uc.tipo_avaliacao = 'N'
      if (!this.fastModeloUcs.uc.id_curso_uc_recuperacao) this.fastModeloUcs.uc.id_curso_uc_recuperacao = 0
      if (!this.fastModeloUcs.uc.id_secretaria_curso_proximo_uc) this.fastModeloUcs.uc.id_secretaria_curso_proximo_uc = 0
      if (!this.fastModeloUcs.uc.id_secretaria_curso_proximo_programa) this.fastModeloUcs.uc.id_secretaria_curso_proximo_programa = 0
      if (!this.fastModeloUcs.uc.id_secretaria_avaliacao_principal) this.fastModeloUcs.uc.id_secretaria_avaliacao_principal = 0
      if (!this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao) this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao = 0
      
      if (!this.fastModeloUcs.uc.avaliacao_configurada) this.fastModeloUcs.uc.avaliacao_configurada = false
      else
        this.fastModeloUcs.uc.avaliacao_configurada = true

      if (!this.fastModeloUcs.uc.recuperacao_configurada) this.fastModeloUcs.uc.recuperacao_configurada = false
      else
        this.fastModeloUcs.uc.recuperacao_configurada = true
      
        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
            this.promisePostFastApi(this.fastModeloUcs.uc, "api/fast_secretaria_avaliacao_uc/atualiza")
            .then((res) => {
                this.exibeToasty("Dados atualizados com sucesso", "success");
                this.hideModal('modalExibirUnidadesCurriculares')
            }).catch((e) => {
                this.exibeToasty("Erro ao atualizar dados", "error");
            });
        }     
    },
    alteraPeriodoAvaliacao(event) {
      switch (event.srcElement.value) {
        case 'D':
          this.fastAvaliacaoConfiguracao.final_modulo = false    
          this.fastAvaliacaoConfiguracao.liberar_prazo = 30  
          this.editaAvaliacaoConfiguracao()    
          break;
        case 'F':
          this.fastAvaliacaoConfiguracao.final_modulo = true
          this.fastAvaliacaoConfiguracao.liberar_prazo = 0
          this.editaAvaliacaoConfiguracao()    
          break;
        default:
          break;
      }
    },
    alteraPeriodoRecuperacao(event) {
      switch (event.srcElement.value) {
        case 'D':
          this.fastRecuperacaoConfiguracao.final_modulo = false    
          this.fastRecuperacaoConfiguracao.liberar_prazo = 30  
          this.editaRecuperacaoConfiguracao()    
          break;
        case 'F':
          this.fastRecuperacaoConfiguracao.final_modulo = true
          this.fastRecuperacaoConfiguracao.liberar_prazo = 0
          this.editaRecuperacaoConfiguracao()    
          break;
        default:
          break;
      }
    },
    editaAvaliacaoConfiguracao(){
      let acao = "insere"
      let erros = []

      if (!this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc) this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc = this.fastModeloUcs.uc.id_secretaria_avaliacao_uc
      if (!this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc) erros.push("Campo não identificado: id_secretaria_avaliacao_uc")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        if (this.fastAvaliacaoConfiguracao.id_avaliacao_configuracao) {
          acao = 'atualiza'
          this.fastAvaliacaoArredondamentoNovo.id_avaliacao_configuracao = this.fastAvaliacaoConfiguracao.id_avaliacao_configuracao
        }
        if (!this.fastAvaliacaoConfiguracao.id_resultado_passar_direto) this.fastAvaliacaoConfiguracao.id_resultado_passar_direto = 0
        if (!this.fastAvaliacaoConfiguracao.id_resultado_media_aprovacao) this.fastAvaliacaoConfiguracao.id_resultado_media_aprovacao = 0
        if (!this.fastAvaliacaoConfiguracao.id_resultado_media_recuperacao) this.fastAvaliacaoConfiguracao.id_resultado_media_recuperacao = 0
        if (!this.fastAvaliacaoConfiguracao.nota_maxima) this.fastAvaliacaoConfiguracao.nota_maxima = 0.00
        if (!this.fastAvaliacaoConfiguracao.passar_direto) this.fastAvaliacaoConfiguracao.passar_direto = 0.00
        if (!this.fastAvaliacaoConfiguracao.media_aprovacao) this.fastAvaliacaoConfiguracao.media_aprovacao = 0.00
        if (!this.fastAvaliacaoConfiguracao.media_recuperacao) this.fastAvaliacaoConfiguracao.media_recuperacao = 0.00
        if (!this.fastAvaliacaoConfiguracao.liberar_prazo) this.fastAvaliacaoConfiguracao.liberar_prazo = 0

        this.fastAvaliacaoConfiguracao.tipo = "A"
        
        this.promisePostFastApi(this.fastAvaliacaoConfiguracao, "api/fast_secretaria_avaliacao_configuracao/" + acao)
        .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success")
              if (acao == "insere") this.fastAvaliacaoConfiguracao.id_avaliacao_configuracao = res[0].id_avaliacao_configuracao
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error")
            }            
            // Recupera modelo ucs
            this.getModeloUcs(this.$route.params.id_plataforma, this.fastModeloUcs.uc.id_avaliacao_modelo)
        }).catch((e) => {
            this.exibeToasty("Erro ao atualizar dados", "error");
        });
      }

      
    },
    editaRecuperacaoConfiguracao(){
      let acao = "insere"
      let erros = []
      if (!this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc) this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc = this.fastModeloUcs.uc.id_secretaria_avaliacao_uc
      if (!this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc) erros.push("Campo não identificado: id_secretaria_avaliacao_uc")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        if (this.fastRecuperacaoConfiguracao.id_avaliacao_configuracao) {
          acao = 'atualiza'
          this.fastRecuperacaoArredondamentoNovo.id_avaliacao_configuracao = this.fastRecuperacaoConfiguracao.id_avaliacao_configuracao
        } 
        if (!this.fastRecuperacaoConfiguracao.id_resultado_passar_direto) this.fastRecuperacaoConfiguracao.id_resultado_passar_direto = 0
        if (!this.fastRecuperacaoConfiguracao.id_resultado_media_aprovacao) this.fastRecuperacaoConfiguracao.id_resultado_media_aprovacao = 0
        if (!this.fastRecuperacaoConfiguracao.id_resultado_media_recuperacao) this.fastRecuperacaoConfiguracao.id_resultado_media_recuperacao = 0
        if (!this.fastRecuperacaoConfiguracao.nota_maxima) this.fastRecuperacaoConfiguracao.nota_maxima = 0.00
        if (!this.fastRecuperacaoConfiguracao.passar_direto) this.fastRecuperacaoConfiguracao.passar_direto = 0.00
        if (!this.fastRecuperacaoConfiguracao.media_aprovacao) this.fastRecuperacaoConfiguracao.media_aprovacao = 0.00
        if (!this.fastRecuperacaoConfiguracao.media_recuperacao) this.fastRecuperacaoConfiguracao.media_recuperacao = 0.00
        if (!this.fastRecuperacaoConfiguracao.liberar_prazo) this.fastRecuperacaoConfiguracao.liberar_prazo = 0

        this.fastRecuperacaoConfiguracao.tipo = "R"
        
        this.promisePostFastApi(this.fastRecuperacaoConfiguracao, "api/fast_secretaria_avaliacao_configuracao/" + acao)
        .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success")
              if (acao == "insere") this.fastRecuperacaoConfiguracao.id_avaliacao_configuracao = res[0].id_avaliacao_configuracao
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error")
            } 
        }).catch((e) => {
            this.exibeToasty("Erro ao atualizar dados", "error");
        });
      }
      
    },
    insereAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoNovo
      } else {
        obj = this.fastRecuperacaoArredondamentoNovo
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/insere")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          this.$store.state.fastCarregando = false;
          
                   
          if (tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res    
            this.hideModal('modalCriarAvaliacaoArredondamento')
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res  
            this.hideModal('modalCriarRecuperacaoArredondamento')   
          }    
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    exibeModalEditarAvaliacaoArredondamento(tipo, obj){
      if (tipo == "A") {
        this.fastAvaliacaoArredondamentoEditar = obj
        this.showModal('modalEditarAvaliacaoArredondamento')
      } else {
        this.fastRecuperacaoArredondamentoEditar = obj
        this.showModal('modalEditarRecuperacaoArredondamento')
      }
    },
    editaAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoEditar
      } else {
        obj = this.fastRecuperacaoArredondamentoEditar
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/atualiza")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          if (tipo == "A") {
            this.hideModal('modalEditarAvaliacaoArredondamento')
          } else {
            this.hideModal('modalEditarRecuperacaoArredondamento')
          }
          
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    excluiAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoEditar
      } else {
        obj = this.fastRecuperacaoArredondamentoEditar
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/exclui")
      .then((res) => {
          this.exibeToasty("Exclusão efetuada com sucesso", "success");
          if (tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res  
            this.hideModal('modalEditarAvaliacaoArredondamento')
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res 
            this.hideModal('modalEditarRecuperacaoArredondamento')
          }
          
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    adicionaCasaDecimal(value){      
      let resultado = 0.00
      if (value) {
        resultado = parseFloat(value)
      }
      return resultado.toFixed(2)
    },
    getSecretariaAvaliacao(id_plataforma, id_secretaria_avaliacao, tipo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_avaliacao=" + id_secretaria_avaliacao)
      .then(obj => {   
        if (obj.length) {
          if (tipo == 'A') this.fastSecretariaAvaliacao = obj[0]
          else
          this.fastSecretariaRecuperacao = obj[0]

          this.getSecretariaQuestoes(id_plataforma, id_secretaria_avaliacao, tipo)
        }
      }).catch(e => {
        console.log(e);
      })
    },
    getSecretariaQuestoes(id_plataforma, id_secretaria_avaliacao, tipo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_questoes/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_avaliacao=" + id_secretaria_avaliacao)
      .then(obj => {   
        if (obj.length) {
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          let peso_questoes = 0
          questoesUnicas.forEach((a, index) => {
            peso_questoes += a.questao_peso
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });


          if (tipo == 'A') {
            this.fastSecretariaAvaliacaoQuestoes = questoesUnicas
            this.fastSecretariaAvaliacaoPorcentagem = (peso_questoes * 100) / this.fastAvaliacaoConfiguracao.nota_maxima
            this.fastSecretariaAvaliacaoQuestoesPeso = peso_questoes

          }
          else{
            this.fastSecretariaRecuperacaoQuestoes = questoesUnicas
            this.fastSecretariaRecuperacaoPorcentagem = (peso_questoes * 100) / this.fastRecuperacaoConfiguracao.nota_maxima
            this.fastSecretariaRecuperacaoQuestoesPeso = peso_questoes

          }
          
        }
      }).catch(e => {
        console.log(e);
      })
    },
    insereSecretariaAvaliacao(tipo){
      console.log("idsecavalaval: ", this.fastSecretariaAvaliacao)
      console.log("idsecavalrec: ", this.fastSecretariaRecuperacao)
      let erros = []

      let obj = {}
      if (tipo == "A") {
        obj = this.fastSecretariaAvaliacao
      } else {
        obj = this.fastSecretariaRecuperacao
      }

      if (!obj.titulo_avaliacao) erros.push("O título da avaliação é obrigatório")
      if (erros.length){
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          if (!obj.tempo_limite) obj.tempo_limite = '00:00'
          if (!obj.tentativas_maxima) obj.tentativas_maxima = 1
          if (!obj.randomizar_ordem) obj.randomizar_ordem = false
          if (!obj.limite_questoes) obj.limite_questoes = 0


          this.$store.state.fastCarregando = true
          this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao/insere")
          .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success");
              this.$store.state.fastCarregando = false;                   
              if (tipo == "A") {
                this.fastModeloUcs.uc.id_secretaria_avaliacao_principal = res[0].id_secretaria_avaliacao 
                this.fastSecretariaAvaliacao.id_secretaria_avaliacao  = res[0].id_secretaria_avaliacao 
                this.hideModal("modalCriarAvaliacaoTemplate")
                this.editaAvaliacaoUc()
              } else {
                this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao = res[0].id_secretaria_avaliacao
                this.fastSecretariaRecuperacao.id_secretaria_avaliacao  = res[0].id_secretaria_avaliacao 
                this.hideModal("modalCriarRecuperacaoTemplate")
                this.editaAvaliacaoUc()
              }   
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
            }
              
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
          });
      }
     

    },
    editaSecretariaAvaliacao(tipo){
      let erros = []

      let obj = {}
      if (tipo == "A") {
        obj = this.fastSecretariaAvaliacao
      } else {
        obj = this.fastSecretariaRecuperacao
      }

      if (!obj.titulo_avaliacao) erros.push("O título da avaliação é obrigatório")
      if (!obj.id_secretaria_avaliacao) erros.push("Identificador da avaliação não identificado")
      if (erros.length){
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          if (!obj.tempo_limite) obj.tempo_limite = '00:00'
          if (!obj.tentativas_maxima) obj.tentativas_maxima = 1
          if (!obj.randomizar_ordem) obj.randomizar_ordem = false
          if (!obj.limite_questoes) obj.limite_questoes = 0


          this.$store.state.fastCarregando = true
          this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao/atualiza")
          .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success");
              this.$store.state.fastCarregando = false;                   
              
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
            }
              
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
          });
      }
     

    },
    async atualizaPesoQuestaoAvaliacao(questao, tipo) {
      let fast_secretaria_avaliacao_questoes = {
        id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
        id_questao: questao.id_questao,
        questao_peso: questao.questao_peso,
      };

      if (tipo == "R") {
        fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao = this.fastSecretariaRecuperacao.id_secretaria_avaliacao
      }

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(fast_secretaria_avaliacao_questoes, "api/fast_secretaria_avaliacao_questoes/atualiza")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");         
          this.getSecretariaQuestoes(this.$route.params.id_plataforma, fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao, tipo)
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    exibeModalExcluirQuestaoAvaliacao(questao, tipo){
      let fast_secretaria_avaliacao_questoes = {
        id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
        id_questao: questao.id_questao,
        questao_peso: questao.questao_peso,
      };

      if (tipo == "R") {
        fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao = this.fastSecretariaRecuperacao.id_secretaria_avaliacao
      }

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(fast_secretaria_avaliacao_questoes, "api/fast_secretaria_avaliacao_questoes/exclui")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");         
          this.getSecretariaQuestoes(this.$route.params.id_plataforma, fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao, tipo)
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });
    },
    // Tipo de resultado
    async getfastResultados(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_resultado_aluno/lista", "id_plataforma=" + id_plataforma)
      .then(obj => {
        if (obj.length) {
          let aprovacao = obj.filter(e => e.indica_aprovacao)
          this.fastResultados.total = aprovacao
          this.fastResultados.filtro = aprovacao
        }
        else {
          this.fastResultados.total = []
          this.fastResultados.filtro = []
        }            
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar resultados", "error")
      })
    },
    // Emails personalizados
    async getFastPlataformaEmail(id_plataforma, emailAvaliacao, emailRecuperacao, emailAprovacao) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_email/lista?id_plataforma=" +
            id_plataforma +
            "&id_email=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {          

          obj.forEach((e) => {
            if (e.ativo == "S") e.ativo = true;
            else e.ativo = false;
            this.validaOpcoesEmail(e)
          });

          let fastEmailAvaliacao = obj.filter(e => e.nome_email == emailAvaliacao)
          if (fastEmailAvaliacao.length) this.fastEmailAvaliacao = fastEmailAvaliacao[0]
          
          let fastEmailRecuperacao = obj.filter(e => e.nome_email == emailRecuperacao)
          if (fastEmailRecuperacao.length) this.fastEmailRecuperacao = fastEmailRecuperacao[0]

          let fastEmailAprovacao = obj.filter(e => e.nome_email == emailAprovacao)
          if (fastEmailAprovacao.length) this.fastEmailAprovacao = fastEmailAprovacao[0]
        } 
      } catch (e) {
        console.log("Erro", e);
      }
    },
    editarEmail(fastEmail) {
      if (fastEmail.html_email_padrao != fastEmail.html_email) fastEmail.customizado = "S";
      else fastEmail.customizado = "N";
      
      if (fastEmail.ativo) fastEmail.ativo = "S";
      else fastEmail.ativo = "N";

      this.$store.state.fastCarregando = true

      this.promisePostFastApi(fastEmail, "api/fast_plataforma_email/atualiza")
      .then(res => {
          this.$store.state.fastCarregando = false
          this.hideModal("modalEditarEmailAvaliacao")
          this.hideModal("modalEditarEmailRecuperacao")
          this.hideModal("modalEditarEmailAprovacao")
          this.exibeToasty("Email atualizado com sucesso", "success")
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false
          this.exibeToasty("Erro ao atualizar email", "error")
        })
    },
    setImageAvaliacao(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "email", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else {
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email             
                this.fastEmailAvaliacao.html_email += `<img src="${retorno.url_image}" style="max-width: 100%">`
                this.exibeToasty("Imagem inserida com sucesso", "success");
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
    setImageRecuperacao(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "email", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else {
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email             
                this.fastEmailRecuperacao.html_email += `<img src="${retorno.url_image}" style="max-width: 100%">`
                this.exibeToasty("Imagem inserida com sucesso", "success");
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
    setImageAprovacao(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "email", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else {
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email             
                this.fastEmailAprovacao.html_email += `<img src="${retorno.url_image}" style="max-width: 100%">`
                this.exibeToasty("Imagem inserida com sucesso", "success");
              }
            }
          }).catch(e => {         
            console.log(e)
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
  min-height: 100px;
}
/** Alternativas */
.alt-correta-true {
  background-color: rgb(62, 255, 111);
}
</style>
